import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Transaction from "./Transaction";
import { getFormDate, getToDate } from "../../redux/actions/adminAction";
// import { InputGroup, Form } from 'react-bootstrap'
import DatePicker from "react-datepicker";
import moment from "moment";
import ReportTransactionList from "./report_transaction";
const TransactionTab = () => {
    const [activeTab, setActiveTab] = useState("transaction");
    const dispatch = useDispatch()
    const [FromDate, setFromDate] = useState("");
    const [ToDate, setToDate] = useState("");
    const today = new Date();
    const [selectedOption, setSelectedOption] = useState('all');

    const handleDailyClick = () => {
        const todayFormatted = moment().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
        dispatch(getFormDate(todayFormatted));
        dispatch(getToDate(todayFormatted));
    };

    const handleWeeklyClick = () => {
        const startOfWeek = moment().startOf('week').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
        const endOfWeek = moment().endOf('day').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
        dispatch(getFormDate(startOfWeek));
        dispatch(getToDate(endOfWeek));
    };

   const handleMonthlyClick = () => {
    const startOfMonth = moment().startOf('month').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
    const endOfMonth = moment().endOf('month').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
    dispatch(getFormDate(startOfMonth));
    dispatch(getToDate(endOfMonth));
};

    const DateStartGet = (date) => {
        if (date) {
            setFromDate(date);
            dispatch(getFormDate(date))
        } else {
            setFromDate("");
            dispatch(getFormDate(""))
        }
    };

    const DateEndGet = (date) => {
        if (date === null) {
            setToDate("");
            dispatch(getToDate(""))
        } else {
            setToDate(date);
            dispatch(getToDate(date))
        }
    };

    const callHandleChange = (event) => {
        setSelectedOption(event.target.value);
        // Handle logic based on selected option
        switch (event.target.value) {
            case 'daily':
                handleDailyClick();
                break;
            case 'weekly':
                handleWeeklyClick();
                break;
            case 'monthly':
                handleMonthlyClick();
                break;
            case 'all':
                handleAllClick();
                break;
            default:
                break;
        }
    };

    const handleAllClick = () => {
        dispatch(getFormDate(""));
        dispatch(getToDate(""));
    };
    
    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
        localStorage.setItem("activeTab", tabName);
    };


    return (
        <>
            <div className="row">
                <div className="col-12 ">
                    <div className="row p-1 Tab mt-1">
                        <div className="col-2 pt-2">
                            <span
                                onClick={() => handleTabClick("transaction")}
                                className={
                                    activeTab === "transaction"
                                        ? "active mx-3 mb-3 pb-3"
                                        : "mx-3 mb-3 pb-2"
                                }
                            >
                                Transaction
                            </span>
                        </div>
                        <div className="col-3 pt-2">
                            <span
                                onClick={() => handleTabClick("report_transaction_list")}
                                className={
                                    activeTab === "report_transaction_list"
                                        ? "active mx-3 mb-3 pb-3"
                                        : "mx-3 mb-3 pb-2"
                                }
                            >
                                Report transaction history
                            </span>
                        </div>
                        <div className="col-2" id="datepickField">
                            <DatePicker
                                selected={FromDate}
                                startDate={FromDate}
                                endDate={ToDate}
                                onChange={(date) => DateStartGet(date)}
                                dateFormat="dd/MM/yyyy"
                                maxDate={today}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                placeholderText="Enter From Date"
                                isClearable={(FromDate !== "" && FromDate !== null) ? true : false}
                            />
                        </div>
                        <div className="col-2 mx-2" id="datepickField">
                            <DatePicker
                                selected={ToDate}
                                startDate={FromDate}
                                endDate={ToDate}
                                minDate={FromDate}
                                onChange={(date) => DateEndGet(date)}
                                dateFormat="dd/MM/yyyy"
                                maxDate={today}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                placeholderText="Enter To Date"
                                isClearable={ToDate !== "" ? true : false}
                            />
                        </div>
                    <div className="col-2 ">
                            <select value={selectedOption} onChange={callHandleChange} className="ps-2 pe-2 pb-2 pt-1">
                                <option value="all">All</option>
                                <option value="daily">Daily</option>
                                <option value="weekly">Weekly</option>
                                <option value="monthly">Monthly</option>
                            </select>
                        </div>
                        <span className="col-12 border-bottom mt-2" style={{ width: "98%", marginLeft: "1%" }}></span>
                    </div>
                    {activeTab === "transaction" && <Transaction />}
                    {activeTab === "report_transaction_list" && <ReportTransactionList />}

                </div>
            </div>
        </>
    );
};

export default TransactionTab;
