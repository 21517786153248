
import LogoutAPI from "../../Api/LogoutApi";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { clearRedux, getAccessToken } from "../../redux/actions/adminAction";

const CommonLogout = async (accessToken, dispatch) => {
    if(accessToken !== "" && accessToken !== null) {
        const responce = await LogoutAPI(accessToken);
        if(responce?.status === 200){
            setTimeout(() => {
                toast.success(responce.msg);
                dispatch(clearRedux());
                dispatch(getAccessToken(""))
                localStorage.clear();
                // dispatch(getDeviceId(""));
                localStorage.removeItem("access_token");
            }, 200);
        } else {
            setTimeout(() => {
                dispatch(getAccessToken(""))
                // window.location.replace(process.env.REACT_APP_BASE_URL+`user/del_login/${location?.host}`);
                dispatch(clearRedux());
                localStorage.clear();
                // dispatch(getDeviceId(""));
                localStorage.removeItem("access_token");
            }, 200);
        }
    };
}

export default CommonLogout;