import React, { useContext, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useDispatch, useSelector } from "react-redux";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import { CommonDateTime } from "../../Api/commonDateTime";
import CommonPagination from "../commonModel/commonPagination";
// import { UserDetail } from "../commonModel/getuser";
import { getFormDate, getToDate } from "../../redux/actions/adminAction";
import DatePicker from "react-datepicker";
import { ExportData } from "../commonModel/commonExport";

const PaymentManagement = () => {
  const { websocket } = useContext(WebSocketContext);
  const { paymentList, search, userId, userList ,accessToken,formDate,toDate} = useSelector((state) => state.adminReducers);
  const [currentPage, setCurrentPage] = useState(1);
  const [FromDate, setFromDate] = useState("");
  const [ToDate, setToDate] = useState("");
  const today = new Date();
  const dispatch = useDispatch()


 const UserDetail = (id) => {
    const userInf = userList?.list?.filter((user) => user?.id === id)[0]
    return {
      userName: userInf?.username,
      useEmail: userInf?.email,
      mobileNUmber: userInf?.phone,
      user_code:userInf?.user_code
    }
  }

  useEffect(() => {
    const param = {
      "transmit": "broadcast",
      "url": "payment_list",
      "request": {
        "limit": "20",
        "page_no": search ? 1 : currentPage,
        "search": search || "",
        "user_id": userId || "",
        "order_by": "-updated_at"
      }
    };
    if (formDate && toDate) {
      param.request.from_date = CommonDateTime(formDate, "YYYY-MM-DD", "hide");
      param.request.to_date = CommonDateTime(toDate, "YYYY-MM-DD", "hide");
    }
    wsSend_request(websocket, param);
  }, [websocket, currentPage, search, userId,formDate,toDate]);


  const handleExport = async () => {
    const LocationURL = "/payment-management";
    if (LocationURL === "/payment-management") {
        const url = `${process.env.REACT_APP_BASE_URL}payment/export/payment/`;
        try {
            await ExportData(url, accessToken, formDate, toDate,"payment");
        } catch (error) {
            console.error('Error while exporting data:', error);
            // Handle error state or display error message
        }
    }
};

  const renderTrack = ({ style, ...props }) => {
    const trackStyle = {
      display: "none"
    };
    return <div style={{ ...style, ...trackStyle }} {...props} />;
  };

  const DateStartGet = (date) => {
    if (date) {
      setFromDate(date);
      dispatch(getFormDate(date))
    } else {
      setFromDate("");
      dispatch(getFormDate(""))
    }
  };

  const DateEndGet = (date) => {
    if (date === null) {
      setToDate("");
      dispatch(getToDate(""))
    } else {
      setToDate(date);
      dispatch(getToDate(date))
    }
  };


  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    
    
       <div className="row">
        <div className="col-10 mt-2 mb-2 ms-2">
          <div className="row ">
            <div id="datepickField" className="col-4 mr-auto mx-3">
              <DatePicker
                selected={FromDate}
                startDate={FromDate}
                endDate={ToDate}
                onChange={(date) => DateStartGet(date)}
                dateFormat="dd/MM/yyyy"
                maxDate={today}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText="Enter From Date"
                isClearable={(FromDate !== "" && FromDate !== null) ? true : false}
              />
            </div>
            <div id="datepickField" className="col-4 mx-3">
              <DatePicker
                selected={ToDate}
                startDate={FromDate}
                endDate={ToDate}
                minDate={FromDate}
                onChange={(date) => DateEndGet(date)}
                dateFormat="dd/MM/yyyy"
                maxDate={today}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText="Enter To Date"
                isClearable={ToDate !== "" ? true : false}
              />
            </div>
            <div className="col-4 ">
              <button className="pt-1 pb-1" id="button" onClick={handleExport}> <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" height={20} width={20}><g id="Export" fill="#ffff"><path d="m46 54.49h-30a6.51 6.51 0 0 1 -6.49-6.49v-30a6.51 6.51 0 0 1 6.5-6.5h14.99a1.5 1.5 0 0 1 0 3h-15a3.5 3.5 0 0 0 -3.5 3.5v30a3.5 3.5 0 0 0 3.5 3.5h30a3.5 3.5 0 0 0 3.5-3.5v-15a1.5 1.5 0 0 1 3 0v15a6.51 6.51 0 0 1 -6.5 6.49z" /><path d="m53 25.5a1.5 1.5 0 0 1 -1.5-1.5v-11.49h-11.5a1.5 1.5 0 0 1 0-3h13a1.5 1.5 0 0 1 1.5 1.5v12.99a1.5 1.5 0 0 1 -1.5 1.5z" /><path d="m33.18 32.32a1.5 1.5 0 0 1 -1.06-2.56l19.81-19.76a1.5 1.5 0 0 1 2.12 2.12l-19.81 19.76a1.49 1.49 0 0 1 -1.06.44z" /></g></svg>
              Export</button>
            </div>
          </div>

        </div>
      <div style={{ margin: "2px" }} className="fixTableHead">
      <Scrollbars
        style={{ height: paymentList?.pagination?.total_records > 20 ? "calc(100vh - 200px)" : "calc(100vh - 170px)" }}
        renderView={props => <div {...props} className="view" />}
        renderTrackHorizontal={renderTrack}
        className="ScrollbarsSidebar"
      >
        <Table responsive style={{ borderTopLeftRadius: "19px", borderTopRightRadius: "19px" }}>
          <thead style={{ position: "sticky", top: "0px" }}>
            <tr className="user-management-table-heading">
              <th>Date</th>
              <th>Time</th>
              <th>Transaction Id</th>
              <th>User Id</th>
              <th>Amount</th>
              <th>Payment Method</th>
              <th>Payment Channel</th>
            </tr>
          </thead>
          <tbody>
            {paymentList?.list.length > 0 ? (
              paymentList.list.map((payment) => {
                // Fetch user details outside of render to avoid hook issues
                const user = UserDetail(payment?.user_id);
                return (
                  <tr key={payment.id} className="user-management-table-detail">
                    <td style={{ cursor: "default" }}>{CommonDateTime(payment.created_at, "DD MMM YYYY", "show")}</td>
                    <td style={{ cursor: "default" }}>{CommonDateTime(payment.created_at, "hh:mm A", "show")}</td>
                    <td style={{ cursor: "default" }}>{payment.transaction_id}</td>
                    <td style={{ cursor: "default" }}>{user?.user_code || "-"}</td>
                    <td style={{ cursor: "default" }}>{`Rm ${payment.total_amount}`}</td>
                    <td style={{ cursor: "default" }}>{payment.payment_method || "-"}</td>
                    <td style={{ cursor: "default" }}>{payment.payment_channel || "-"}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="7">Data not found</td>
              </tr>
            )}
          </tbody>
        </Table>
      </Scrollbars>
      </div>
      {paymentList?.pagination?.total_records > 20 && (
        <CommonPagination
          currentPage={currentPage}
          paginationLength={paymentList?.pagination}
          currentFunction={handlePageChange}
        />
      )}
    </div>
  );
};

export default PaymentManagement;
