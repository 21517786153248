import React, { useContext, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import 'moment-timezone';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useSelector } from "react-redux";
import 'moment-timezone';
import CommonPagination from "../commonModel/commonPagination";
import { WebSocketContext } from "../../App";
import wsSend_request from "../../Api/ws/ws_request";
import CommonewModel from "../commonModel/commonModel";
import { CommonDateTime } from "../../Api/commonDateTime";

const RefundManagement = () => {
  const { search,refundList ,refundListApi} = useSelector((state) => state.adminReducers);
  const [currentPage, setCurrentPage] = useState(1);
  const { websocket } = useContext(WebSocketContext);

  const [showModalNew, setShowModalNew] = useState({
    open: false,
    title: "",
    modalType: "",
    Data: {}
});

  const renderTrack = ({ style, ...props }) => {
    const trackStyle = {
      display: "none"
    };
    return <div style={{ ...style, ...trackStyle }} {...props} />;
  };

  

  const refundStatus = (id) => {
    const refund = refundListApi?.list?.filter((refund) => refund?.transaction_id === id)[0]
    return {
      status: refund?.status
    }
  }


  const openModal = (id,status) => {
    setShowModalNew({
        ...showModalNew,
        open: !showModalNew?.open,
        title: "Create New Box",
        subtitle: "Create New Model",
        modalType: status,
        Data: id
    })
};

  useEffect(() => {
    let param = {
      "transmit": "broadcast",
      "url": "refund_req_list",
      "request": {
        "date": "",
        "limit": "20",
        "page_no": search ? 1 : currentPage,
        "search": search ? search : "",
      }
    }
    wsSend_request(websocket, param)
  }, [websocket, currentPage, search])
  
  useEffect(() => {
    let param = {
      "transmit": "broadcast",
      "url": "refund_list",
      "request": {
        "limit": "5000",
        "page_no": currentPage,
      }
    }
    wsSend_request(websocket, param)
  }, [websocket, currentPage])

  const currentFunction = (page) => {
    setCurrentPage(page);
  };
  const UserDetail = (id) => {
    const { userList} = useSelector((state) => state.adminReducers);

    const userInf = userList?.list?.filter((user) => user?.id === id)[0]
    return {
      userName: userInf?.username,
      useEmail: userInf?.email,
      mobileNUmber: userInf?.phone,
      user_code:userInf?.user_code
    }
  }

  return (
    <>
      <div style={{ margin: "2px" }} className="fixTableHead">
        <Scrollbars
          style={{ height: refundList?.pagination?.total_records > 20 ? "calc(100vh - 160px)" : "calc(100vh - 125px)" }}
          renderView={props => <div {...props} className="view" />}
          renderTrackHorizontal={renderTrack}
          className="ScrollbarsSidebar"
        >
          <Table responsive style={{ borderTopLeftRadius: "19px", borderTopRightRadius: "19px" }} >
            <thead style={{ position: "sticky", top: "0px" }}>
              <tr className="user-management-table-heading">
                <th>Date</th>
                <th>Time</th>
                <th>User Id</th>
                <th>Transaction Id</th>
                <th>Transaction Amount</th>
                <th>Note</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {refundList?.list.length > 0 ? (
                refundList.list.map((refund) => (
                  <tr key={refund.id} className="user-management-table-detail">
                    <td style={{ cursor: "default" }}>{CommonDateTime(refund.created_at, "DD MMM YYYY", "show")}</td>
                    <td style={{ cursor: "default" }}>{CommonDateTime(refund.created_at, "hh:mm A", "show")}</td>
                    <td style={{ cursor: "default" }}>{UserDetail(refund.user_id)?.user_code?UserDetail(refund.user_id)?.user_code:"-"}</td>
                    <td style={{ cursor: "default" }}>{refund.transaction_id || "-"}</td>
                    <td style={{ cursor: "default" }}>{refund.total_amount || "-"}</td>
                    <td style={{ cursor: "default" }}>{refund.notes}</td>
                    {refundStatus(refund.transaction_id)?.status === "Processing"?<td style={{ cursor: "default" }}>
                        <span style={{backgroundColor:"#F7ABAB",color:"#000000",borderRadius:"4px",cursor:"pointer"}} className="ps-3 pe-3" onClick={()=>openModal(refund.transaction_id,"Reject")}>Rejected</span>
                        <span style={{backgroundColor:"#B6EFBB",color:"#000000",borderRadius:"4px",cursor:"pointer"}} className="mx-2 ps-3 pe-3" onClick={()=>openModal(refund.transaction_id,"Accept")}>Accepted</span>
                    </td>:<td>
                              <span style={{backgroundColor:refundStatus(refund.transaction_id)?.status==="Reject"?"#F7ABAB":"#B6EFBB",color:"#000000",borderRadius:"4px",cursor:"pointer",}} className="ps-5 pe-5">{refundStatus(refund.transaction_id)?.status==="Reject"?"Rejected":"Accepted"}</span>
                        </td>}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4">Data not found</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Scrollbars>
        {refundList?.pagination?.total_records > 20 && (<CommonPagination
          currentPage={currentPage}
          paginationLength={refundList?.pagination}
          currentFunction={currentFunction}
        />)}
            {showModalNew?.open && (<CommonewModel setShowModalNew={setShowModalNew} showModalNew={showModalNew} />)}

      </div>
    </>
  );
};

export default RefundManagement;
