/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import 'moment-timezone';
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import { useDispatch, useSelector } from "react-redux";
import { Scrollbars } from 'react-custom-scrollbars-2';
import CommonPagination from "../commonModel/commonPagination";
import EmptySpaceFieldValid from "../../CheckValid/EmptySpaceValid";
import CheckValid from "../../CheckValid/checkValid";
import { ClearFormSetFutios } from "../../redux/actions/adminAction";
import CommonewModel from "../commonModel/commonModel";

const ReportTransaction = () => {
  const { websocket } = useContext(WebSocketContext);
  const {  ClearFormSet,reportList} = useSelector((state) => state.adminReducers);
  const [currentPage, setCurrentPage] = useState(1)
  const [topic,setTopic] = useState("")
  const [errorTopic,setErrorTopic] = useState("")
  const dispatch = useDispatch()

  const [showModalNew, setShowModalNew] = useState({
    open: false,
    title: "",
    modalType: "",
    Data: {}
});

  const openModal = (id,status,title) => {
    setShowModalNew({
        ...showModalNew,
        open: !showModalNew?.open,
        title: status,
        subtitle: "Create New Model",
        modalType: title?title:"delete-popup",
        Data: id
    })
};

  const renderTrack = ({ style, ...props }) => {
    const trackStyle = {
      display: "none"
    };
    return <div style={{ ...style, ...trackStyle }} {...props} />;
  };

  const currentFunction = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (ClearFormSet?.url === "report_add" && ClearFormSet?.action === 200) {
        call()
        setTopic("");   
    }
    setTimeout(() => {
        dispatch(ClearFormSetFutios({
            url: "",
            action: ""
        }))
    }, 1000)
}, [ClearFormSet, dispatch])

  useEffect(() => { 
    call()
  }, [websocket,currentPage])

  function call(){
    let param = {
        "transmit": "broadcast",
        "url": "report_list",
        "request": {
          "limit": "20",
          "page_no": currentPage,
        }
      }
      wsSend_request(websocket, param)
  }

  function submit(e){
    e.preventDefault()
    if(topic){
        let param = {
            "transmit": "broadcast",
            "url": "report_add",
            "request": {
                "title":topic
            }
          }
          wsSend_request(websocket, param)
    }else{
        CheckValid(topic, { type: 'Topic', error: setErrorTopic })
    }
  }

  return (
    <>
        <div className="row">
            <div className="col-12">
                <div className="row ms-2 me-2">
                    <div className="col-4 ms-1 mb-3 mt-2">
                        <input type="text" 
                        className="form-control" 
                        placeholder="Enter topic for issue"
                        value={topic}
                        onKeyUp={(e) => CheckValid(e.target.value, { type: 'Topic', error: setErrorTopic })}
                        onKeyDown={EmptySpaceFieldValid}
                        onChange={(e)=>setTopic(e.target.value)}
                        />
                        {errorTopic && <span className="text-danger">{errorTopic}</span>}
                    </div>
                    <div className="col-4 mb-3 mt-2">
                    <button className="ps-4 pe-4 pt-2 pb-2"  style={{ backgroundColor: "#181818",cursor:"pointer" ,border: "unset", color: "#FFFFFF", borderRadius: "17px" }}  onClick={submit}>+Add</button>
                    </div>
                </div>            
            </div>
        </div>
      <div style={{ margin: "2px" }} className="fixTableHead ms-3 me-3">
        <Scrollbars
          style={{ height: reportList?.pagination?.total_records > 20 ? "calc(100vh - 220px)" : "calc(100vh - 220px)" }}
          renderView={props => <div {...props} className="view" />}
          renderTrackHorizontal={renderTrack}
          className="ScrollbarsSidebar"
        >
          <Table responsive style={{ borderTopLeftRadius: "19px", borderTopRightRadius: "19px" }} className="border">
            <thead style={{ position: "sticky", top: "0px" }}>
              <tr className="user-management-table-heading">
                <th>Topic</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {reportList?.list.length > 0 ? (
                reportList.list.map((payment) => (
                  <tr key={payment.id} className="user-management-table-detail ">
                    <td style={{ cursor: "default",width:"100%",border:"1px solid" }}>{payment.title}</td>
                    <td style={{ cursor: "default",border:"1px solid" }}>
                    <span style={{backgroundColor:"#F7ABAB",color:"#000000",borderRadius:"4px",cursor:"pointer"}} className="ps-5 pe-5" onClick={()=>openModal(payment.id,"topic","Edit")}>Edit</span>
                    <span style={{backgroundColor:"#B6EFBB",color:"#000000",borderRadius:"4px",cursor:"pointer"}} className="mx-2 ps-5 pe-5" onClick={()=>openModal(payment.id,"report_delete","")} >Delete</span>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4">Data not found</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Scrollbars>
        {reportList?.pagination?.total_records > 20 && (<CommonPagination
          currentPage={currentPage}
          paginationLength={reportList?.pagination}
          currentFunction={currentFunction}
        />)}
      </div>
      {showModalNew?.open && (<CommonewModel setShowModalNew={setShowModalNew} showModalNew={showModalNew} />)}
    </>
  );
};

export default ReportTransaction;
