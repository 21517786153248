import axios from 'axios';
import { PostRequestAPI } from '../../Api/getReq';

export const ExportData = async (url, accessToken,from_date,to_date,type) => {
    try {
        const response =await PostRequestAPI(url, accessToken, false, 'blob',from_date?from_date:"",to_date?to_date:"")
            if (response?.status === 200) {
                const blob = new Blob([response?.data]);

                const blobURL = URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = blobURL;
                link.download = `${type}.xls`; 
                document.body.appendChild(link);
                link.click();
                URL.revokeObjectURL(blobURL);
              }
        
    } catch (error) {
        console.error('Error while exporting data:', error);
        throw error;
    }
};


export const fetchImageWithAuthorization = async (imageUrl, token) => {
    try {
        const response = await axios.get(imageUrl, {
            responseType: 'blob',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'image/jpeg',
            },
        });
        
        return response.data;
    } catch (error) {
        throw error;
    }
};