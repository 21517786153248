import axios from "axios";

export const PostRequestAPI = async (apiurl, access_token, contentType, responseType,from_date,to_date) => {
    const headers = {
      'Accept':"application/json",
    }
    if(access_token){
      headers.Authorization = `Bearer ${access_token}`
    }
    if(contentType){
      headers['Content-Type']='multipart/form-data'
    }

    const params = {
        "from_date":from_date, 
        "to_date":to_date,
      };

    const contentMethod = {
      headers:headers,
      params
    }


    if(responseType){
      contentMethod.responseType = responseType
    }
    const getResponse = axios.get(apiurl, contentMethod).then(function (result) {
        console.log(result)
      return result;
    }).catch((e)=>e.response);
    return getResponse;
};