import React, { useState, useEffect, useContext } from "react";
import { Table } from "react-bootstrap";
import 'moment-timezone';
import CommonewModel from "../commonModel/commonModel";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { WebSocketContext } from "../../App";
import wsSend_request from "../../Api/ws/ws_request";
import { useDispatch, useSelector } from "react-redux";
import CommonPagination from "../commonModel/commonPagination";
import { getFormDate, getToDate } from "../../redux/actions/adminAction";
import DatePicker from "react-datepicker";
import { ExportData } from "../commonModel/commonExport";
import { CommonDateTime } from "../../Api/commonDateTime";

const OrderManagement = () => {
  const { websocket } = useContext(WebSocketContext);
  const { OrderList, userList, search, userId ,formDate, toDate,accessToken} = useSelector((state) => state.adminReducers);
  const [currentPage, setCurrentPage] = useState(1)
  const [FromDate, setFromDate] = useState("");
  const [ToDate, setToDate] = useState("");
  const today = new Date();

  const [showModalNew, setShowModalNew] = useState({
    open: false,
    title: "",
    modalType: "",
    Data: {}
  });
  const dispatch = useDispatch()
 
  useEffect(() => {
    let param = {
      "transmit": "broadcast",
      "url": "order_list",
      "request": {
        "status": "",
        "limit": "20",
        "page_no": search ? 1 : currentPage,
        "search": search ? search : "",
        "user_id": userId ? userId : "",
        "order_by": "-updated_at"
      }
    }
    if (formDate && toDate) {
      param.request.from_date = CommonDateTime(formDate, "YYYY-MM-DD", "hide");
      param.request.to_date = CommonDateTime(toDate, "YYYY-MM-DD", "hide");
    }
    wsSend_request(websocket, param)
  }, [websocket, currentPage,userId,search,formDate,toDate])

  const DateStartGet = (date) => {
    if (date) {
      setFromDate(date);
      dispatch(getFormDate(date))
    } else {
      setFromDate("");
      dispatch(getFormDate(""))
    }
  };

  const DateEndGet = (date) => {
    if (date === null) {
      setToDate("");
      dispatch(getToDate(""))
    } else {
      setToDate(date);
      dispatch(getToDate(date))
    }
  };

  function openModel(id) {
    setShowModalNew({
      ...showModalNew,
      open: !showModalNew?.open,
      title: "Create New Box",
      subtitle: "Create New Order Model",
      modalType: "add-booking-name",
      Data: {
        orderId: id
      }
    })
  }

  const handleExport = async () => {
    const LocationURL = "/transaction-management";
    if (LocationURL === "/transaction-management") {
        const url = `${process.env.REACT_APP_BASE_URL}tcn/export/order/`;
        try {
            await ExportData(url, accessToken, formDate, toDate,"order");
        } catch (error) {
            console.error('Error while exporting data:', error);
            // Handle error state or display error message
        }
    }
};

function orderStatus(status){
    if(status==="PAID"){
      return "#006333"
    }
    if(status==="Unpaid"){
      return "#009FC1"
    }
    if(status==="Pending"){
      return "#F5A200"
    }
    if(status==="Cancel"){
      return "#000000"
    }
}

const UserDetail = (id) => {
  const userInf = userList?.list?.filter((user) => user?.id === id)[0]
  return {
    userName: userInf?.username,
    useEmail: userInf?.email,
    mobileNUmber: userInf?.phone,
    user_code:userInf?.user_code
  }
}
  const currentFunction = (page) => {
    setCurrentPage(page);
  };

  const renderTrack = ({ style, ...props }) => {
    const trackStyle = {
      display: "none"
    };
    return <div style={{ ...style, ...trackStyle }} {...props} />;
  };
  return (
    <>
      <div className="row">
        <div className="col-12 mt-2 mb-2 ms-2">
          <div className="row ">
            <div id="datepickField" className="col-4 mr-auto mx-3">
              <DatePicker
                selected={FromDate}
                startDate={FromDate}
                endDate={ToDate}
                onChange={(date) => DateStartGet(date)}
                dateFormat="dd/MM/yyyy"
                maxDate={today}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText="Enter From Date"
                isClearable={(FromDate !== "" && FromDate !== null) ? true : false}
              />
            </div>
            <div id="datepickField" className="col-4 mx-3">
              <DatePicker
                selected={ToDate}
                startDate={FromDate}
                endDate={ToDate}
                minDate={FromDate}
                onChange={(date) => DateEndGet(date)}
                dateFormat="dd/MM/yyyy"
                maxDate={today}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText="Enter To Date"
                isClearable={ToDate !== "" ? true : false}
              />
            </div>
            <div className="col-4 ">
              <button className="pt-1 pb-1" id="button" onClick={handleExport}> <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" height={20} width={20}><g id="Export" fill="#ffff"><path d="m46 54.49h-30a6.51 6.51 0 0 1 -6.49-6.49v-30a6.51 6.51 0 0 1 6.5-6.5h14.99a1.5 1.5 0 0 1 0 3h-15a3.5 3.5 0 0 0 -3.5 3.5v30a3.5 3.5 0 0 0 3.5 3.5h30a3.5 3.5 0 0 0 3.5-3.5v-15a1.5 1.5 0 0 1 3 0v15a6.51 6.51 0 0 1 -6.5 6.49z" /><path d="m53 25.5a1.5 1.5 0 0 1 -1.5-1.5v-11.49h-11.5a1.5 1.5 0 0 1 0-3h13a1.5 1.5 0 0 1 1.5 1.5v12.99a1.5 1.5 0 0 1 -1.5 1.5z" /><path d="m33.18 32.32a1.5 1.5 0 0 1 -1.06-2.56l19.81-19.76a1.5 1.5 0 0 1 2.12 2.12l-19.81 19.76a1.49 1.49 0 0 1 -1.06.44z" /></g></svg>
              Export</button>
            </div>
          </div>

        </div>
      </div>

      <div style={{ margin: "2px" }} className="fixTableHead ">
        <Scrollbars
          style={{ height: OrderList?.pagination?.total_records > 20 ? "calc(100vh - 160px)" : "calc(100vh - 160px)" }}
          renderView={props => <div {...props} className="view" />}
          renderTrackHorizontal={renderTrack}
          className="ScrollbarsSidebar"
        >
          <Table responsive style={{ borderTopLeftRadius: "19px", borderTopRightRadius: "19px" }}>
            <thead style={{ position: "sticky", top: "0px" }}>
              <tr className="user-management-table-heading">
                <th>Order Id</th>
                <th>Mobile Number</th>
                <th>Machine Id</th>
                <th>Date</th>
                <th>Cost</th>
                <th>Status</th>
                <th>Detail</th>
              </tr>
            </thead>
            <tbody>
              {OrderList?.orders.length > 0 ? (
                OrderList.orders.map((order) => (
                  <tr key={order.id} className="user-management-table-detail">
                    <td>{order.id}</td>
                    <td style={{ cursor: "default" }}>{UserDetail(order?.user_id)?.mobileNUmber || "-"}</td>
                    <td>{order?.machine_name}</td>
                    <td style={{ cursor: "default" }}>{CommonDateTime(order.created_at, "DD MMM YYYY", "show")}</td>
                    <td style={{ cursor: "default" }}>{order.total_amount}</td>
                    <td style={{ cursor: "default" }}>
                      <span style={{color:orderStatus(order.status)}}>{order.status==="PAID"?"Success":order.status}</span>
                      </td>
                    <td>
                      <span className="detail-icon" onClick={() => openModel(order.id)}>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_1126_497)">
                            <path d="M17.6171 13.813C17.2728 13.9893 16.8491 13.8545 16.6713 13.5084H16.6713C15.5932 11.4075 12.7308 8.39545 8.99997 8.39545C5.65408 8.39545 2.68067 10.8737 1.32863 13.5085C1.15077 13.8532 0.727209 13.9893 0.382151 13.8121C0.0371272 13.6348 -0.0994196 13.2121 0.0773108 12.8668C1.63072 9.83348 5.04682 6.9892 8.99997 6.9892C13.5084 6.9892 16.7374 10.5524 17.9226 12.8668C18.0996 13.2125 17.9628 13.6361 17.6171 13.813ZM0.273201 6.26147L1.8392 7.67419C2.12924 7.93586 2.57369 7.90966 2.83226 7.62307C3.09235 7.33472 3.06949 6.89013 2.78114 6.63001L1.21514 5.21729C0.926897 4.95724 0.482311 4.98002 0.222084 5.26841C-0.0380017 5.55676 -0.0151501 6.00135 0.273201 6.26147ZM16.1607 7.67419L17.7267 6.26147C18.015 6.00135 18.0379 5.55676 17.7778 5.26841C17.5177 4.98009 17.0731 4.95717 16.7847 5.21729L15.2188 6.63001C14.9304 6.8901 14.9076 7.33472 15.1676 7.62307C15.4265 7.90998 15.871 7.93554 16.1607 7.67419ZM8.99993 4.87104C9.38827 4.87104 9.70306 4.55621 9.70306 4.16791V2.05664C9.70306 1.66834 9.38827 1.35352 8.99993 1.35352C8.6116 1.35352 8.29681 1.66834 8.29681 2.05664V4.16791C8.29684 4.55625 8.61163 4.87104 8.99993 4.87104ZM12.454 13.1871C12.454 15.0946 10.9045 16.6465 8.99997 16.6465C7.09541 16.6465 5.5459 15.0946 5.5459 13.1871C5.5459 11.2797 7.09541 9.7278 8.99997 9.7278C10.9045 9.7278 12.454 11.2797 12.454 13.1871ZM11.0478 13.1871C11.0478 12.0551 10.1292 11.1341 9 11.1341C7.87082 11.1341 6.95215 12.0551 6.95215 13.1871C6.95215 14.3192 7.87078 15.2402 8.99997 15.2402C10.1292 15.2402 11.0478 14.3192 11.0478 13.1871Z" fill="black" />
                          </g>
                          <defs>
                            <clipPath id="clip0_1126_497">
                              <rect width="18" height="18" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7">Data not found</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Scrollbars>
        {OrderList?.pagination?.total_records > 20 && (<CommonPagination
          currentPage={currentPage}
          paginationLength={OrderList?.pagination}
          currentFunction={currentFunction}
        />)}
        {showModalNew?.open && (<CommonewModel setShowModalNew={setShowModalNew} showModalNew={showModalNew} />)}
      </div>
    </>
  );
};

export default OrderManagement;
