/* eslint-disable react-hooks/rules-of-hooks */
const wsSend_request = (websocket, params)=>{
    var tabID = sessionStorage.tabID ? sessionStorage.tabID : sessionStorage.tabID = Math.random();
    const Updateparams = {
        ...params,
        TabId: tabID,
    };
    if(websocket.readyState === websocket.OPEN ){
        if(Updateparams?.request !== undefined){
            // setTimeout(() => {
                websocket && websocket?.send(JSON.stringify(Updateparams))
            // }, 1000);
        } else {
            websocket && websocket?.send(JSON.stringify(Updateparams))
        };
    }else{
        setTimeout(()=>{
            wsSend_request(websocket, Updateparams)
        },1500)
    };
};
export default wsSend_request;