import React from "react";
import Header from "../Header/Header";
import Sidebar from "../SideBar/SideBar";
// import { useLocation } from "react-router-dom";
const Layout = ({ children }) => {

    // const LocationURL = useLocation().pathname;
    return (
        <div className="container-fluid g-0">
            <div className="row">
                <div className="col-12">
                    <div className="row">
                        <div className="col-2 col-md-2 col-lg-2 col-sm-2">
                            <Sidebar />
                        </div>
                        <div className="col-10 col-md-10 col-lg-10 col-sm-10">
                            <div className="row mt-2">
                                <div className="col-12">
                                    <Header />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 bg-white p-0 ms-2 layout-content" >
                                    {children}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <section className="d-flex">
        <div style={{ width: "269px", height: "100vh" }} className="p-3">
          <Sidebar />
        </div>
        <div className="flex-grow-1">
          <div>
            <Header />
          </div>
          <div className="main  rounded layout-container" >
            <div className="mb-5">
              {children}
            </div>
            </div>
        </div>
      </section> */}
        </div>
    );
};

export default Layout;
