import './App.css';
import { Navigate, Route, Routes } from 'react-router-dom'; // Import BrowserRouter
// import { useState } from 'react';
import Login from './component/Login/index';
import './assets/css/style.css'
import Layout from './component/Layout/Layout';
import UserManagement from './component/UserInformation/UserManagement';
import OrderManagement from './component/OrderManagement/OrderManagement';
// import ReportManagement from './component/ReportManagement/ReportManagement';
import MachineManagement from './component/MachineManagement/MachineManagement';
import CreateMachine from './component/MachineManagement/CreateMachine';
import PaymentManagement from './component/PaymentManagement/PaymentManagement';
import { useState,createContext } from 'react';
import { ToastContainer } from 'react-toastify';
import useWebSocket from './Api/useWebsocket';
import { useSelector } from 'react-redux';
import CreateFaq from './component/FAQ/createFaq';
import FaqManagement from './component/FAQ/faqManagement';
import "react-datepicker/dist/react-datepicker.css";
import Banner from './component/banner/banner';
import PagesTab from './component/pages/pagesTab';
import BenefitManagement from './component/benefit/benifitManagement';
import CreateBenefit from './component/benefit/addBenefit';
import RefundManagement from './component/refund/refundManagement';
import TransactionTab from './component/Transaction/transaction_tab';
import PrivacyPolicy from './component/static/privacy';
import DeleteAccount from './component/static/delete';

export const WebSocketContext = createContext();

function App() {
  const [GetWEBSocket, setWebSocket] = useState("");
  const { accessToken} = useSelector((state) => state.adminReducers);

  useWebSocket(`${process.env.REACT_APP_SERVICE_URL}${localStorage.getItem("access_token")}/`, setWebSocket, localStorage.getItem("access_token"));

  return (<WebSocketContext.Provider value={{ websocket: GetWEBSocket }}>
    <div className="App">
      <Routes>
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/delete-account" element={<DeleteAccount />} />
        <Route path="/login" element={<Login />} />
        <Route path="/login" element={accessToken === "" ? <Login /> : <Navigate  replace to={'/user-management'}/>} >
                <Route path="/login" element={accessToken === "" ? <Login /> : <Navigate  replace to={'/user-management'}/>} />
      </Route>
        <Route path="/user-management" element={accessToken !== "" ?<Layout><UserManagement RouteName="/user-management" /></Layout>:<Navigate replace to ="login"/>} />
        <Route path="/order-management" element={accessToken !== "" ?<Layout><OrderManagement RouteName="/order-management" /></Layout>:<Navigate replace to ="login"/>} />
        {/* <Route path="/contact-us" element={accessToken !== "" ?<Layout><ReportManagement RouteName="/contact-us" /></Layout>:<Navigate replace to ="login"/>} /> */}
        <Route path="/machine-management" element={accessToken !== "" ?<Layout><MachineManagement RouteName="/machine-management" /></Layout>:<Navigate replace to ="login"/>}/>
        <Route path="/add-machine" element={accessToken !== "" ?<Layout><CreateMachine RouteName="/add-machine" /></Layout>:<Navigate replace to ="login"/>} />
        <Route path="/payment-management" element={accessToken !== "" ?<Layout><PaymentManagement RouteName="/payment-management" /></Layout>:<Navigate replace to ="login"/>} />
        <Route path="/transaction-management" element={accessToken !== "" ?<Layout><TransactionTab RouteName="/transaction-management" /></Layout>:<Navigate replace to ="login"/>} />
        {/* <Route path="/page" element={accessToken !== "" ?<Layout><PageManagement RouteName="/page" /></Layout>:<Navigate replace to ="login"/>} />
        <Route path="/add-page" element={accessToken !== "" ?<Layout><CreatePage RouteName="/add-page" /></Layout>:<Navigate replace to ="login"/>} />
        <Route path="/edit-page/:id" element={accessToken !== "" ?<Layout><CreatePage RouteName="/add-page" /></Layout>:<Navigate replace to ="login"/>} /> */}
        <Route path="/edit-machine/:id" element={accessToken !== "" ?<Layout><CreateMachine RouteName="/add-machine" /></Layout>:<Navigate replace to ="login"/>} />
        <Route path="/add-faq" element={accessToken !== "" ?<Layout><CreateFaq RouteName="/add-faq" /></Layout>:<Navigate replace to ="login"/>} />
        <Route path="/edit-faq/:id" element={accessToken !== "" ?<Layout><CreateFaq RouteName="/add-faq" /></Layout>:<Navigate replace to ="login"/>} />
        <Route path="/benefit" element={accessToken !== "" ?<Layout><BenefitManagement RouteName="/benefit" /></Layout>:<Navigate replace to ="login"/>} />
        <Route path="/add-benefit" element={accessToken !== "" ?<Layout><CreateBenefit RouteName="/add-benefit" /></Layout>:<Navigate replace to ="login"/>} />
        <Route path="/edit-benefit/:id" element={accessToken !== "" ?<Layout><CreateBenefit RouteName="/add-benefit" /></Layout>:<Navigate replace to ="login"/>} />
        <Route path="/faq" element={accessToken !== "" ?<Layout><FaqManagement RouteName="/faq" /></Layout>:<Navigate replace to ="login"/>} />
        <Route path="/banner" element={accessToken !== "" ?<Layout><Banner RouteName="/banner" /></Layout>:<Navigate replace to ="login"/>} />
        <Route path="/page" element={accessToken !== "" ?<Layout><PagesTab RouteName="/page" /></Layout>:<Navigate replace to ="login"/>} />
        <Route path="/refund" element={accessToken !== "" ?<Layout><RefundManagement RouteName="/refund" /></Layout>:<Navigate replace to ="login"/>} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
      <ToastContainer />
    </div>
  </WebSocketContext.Provider>
  );
}

export default App;
