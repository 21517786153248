import { useSelector } from "react-redux";

export const UserDetail = (id) => {
    const { userList} = useSelector((state) => state.adminReducers);

    const userInf = userList?.list?.filter((user) => user?.id === id)[0]
    return {
      userName: userInf?.username,
      useEmail: userInf?.email,
      mobileNUmber: userInf?.phone,
      user_code:userInf?.user_code
    }
  }