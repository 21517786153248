/* eslint-disable */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CheckValid from "../../CheckValid/checkValid";
import Login from "../../assets/image/logo_login.png";

const DeleteAccount = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState({
    Email: "",
    Password: "",
    remember: false,
  });
  const { Email, Password } = inputValue;
  const [showPassword, setShowPassword] = useState(false);
  const [loaderStart, setLoaderStart] = useState(false);

  // Regex
  const EmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const PasswordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;

  // Error messages
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPassword, setErrorPassword] = useState("");

  // Show/hide password
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrorEmail("");
    setErrorPassword("");
  };

  // Delete function
  const handleDelete = async () => {
    if (Email !== "" && Password !== "") {
      if (
        EmailRegex.test(Email) &&
        PasswordRegex.test(Password)
      ) {
        setLoaderStart(true);
        const jsonData = JSON.stringify({
          email: Email,
          password: Password,
          device_type: "web",
        });
        // Handle API call here
        toast.success("User Account Deleted Successfully");
        setLoaderStart(false);
        setInputValue({
          Email: "",
          Password: "",
          remember: false,
        });
      } else {
        CheckValid(Email, { type: "email", error: setErrorEmail });
        CheckValid(Password, {
          type: "password",
          error: setErrorPassword,
        });
      }
    } else {
      CheckValid(Email, { type: "email", error: setErrorEmail });
      CheckValid(Password, {
        type: "password",
        error: setErrorPassword,
      });
    }
  };

  return (
    <section className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-8 col-lg-6">
          <div className="text-center mb-4">
            <img
              src={Login}
              alt="logo"
              style={{ height: 80, width: 80}}
              className="mb-3"
            />
            <h4>Account Delete</h4>
            <p className="text-muted">
              Enter your email and password to delete your account.
            </p>
          </div>
          <div className="card p-4">
            <div className="mb-3">
              <label htmlFor="email" className="form-label">Email Address</label>
              <input
                type="email"
                id="email"
                name="Email"
                value={Email}
                onChange={handleChange}
                className={`form-control ${errorEmail ? "is-invalid" : ""}`}
                placeholder="Enter Email Address"
                onKeyUp={(e) =>
                  CheckValid(e.target.value, { type: "email", error: setErrorEmail })
                }
                required
              />
              <div className="invalid-feedback">{errorEmail}</div>
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">Password</label>
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                name="Password"
                value={Password}
                onChange={handleChange}
                className={`form-control ${errorPassword ? "is-invalid" : ""}`}
                placeholder="Enter Password"
                onKeyUp={(e) =>
                  CheckValid(e.target.value, { type: "password", error: setErrorPassword })
                }
                required
              />
              <div className="invalid-feedback">{errorPassword}</div>
              <div className="form-check mt-2">
                <input
                  type="checkbox"
                  id="showPassword"
                  className="form-check-input"
                  checked={showPassword}
                  onChange={togglePasswordVisibility}
                />
                <label htmlFor="showPassword" className="form-check-label ms-2">
                  Show Password
                </label>
              </div>
            </div>
            <div className="d-grid">
              <button
                className="btn btn-success"
                disabled={loaderStart}
                onClick={handleDelete}
              >
                {loaderStart ? "Deleting..." : "Delete"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DeleteAccount;
