import React, { useEffect, useState } from "react";
import 'moment-timezone';
import { Form } from "react-bootstrap";
import EmptySpaceFieldValid from "../../CheckValid/EmptySpaceValid";
import CheckValid from "../../CheckValid/checkValid";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import wsSend_request from "../../Api/ws/ws_request";
import { useContext } from "react";
import { WebSocketContext } from "../../App";
import { useDispatch, useSelector } from "react-redux";
import { ClearFormSetFutios } from "../../redux/actions/adminAction";
const CreateMachine = () => {
    const LocationRoute = useLocation()?.pathname?.split("/")[1];
    const { websocket } = useContext(WebSocketContext);
    const LocationURL = useLocation().pathname;
    const id = useParams()?.id;
    const { ClearFormSet, machineList } = useSelector((state) => state.adminReducers);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [createMachine, setCreateMachine] = useState({
        machineId: "",
        orgId: "",
        doorNo: "",
        address: "",
        active: true
    })
    const [errorMachineId, setErrorMachineId] = useState("")
    const [errorOrgId, setErrorOrgId] = useState("")
    const [errorDoorNo, setErrorDoorNo] = useState("")
    const [errorAddess, setAddressError] = useState("")
    const machineListData = machineList?.list?.filter((data) => data.id === id)[0]

    // const renderTrack = ({ style, ...props }) => {
    //     const trackStyle = {
    //         display: "none"
    //     };
    //     return <div style={{ ...style, ...trackStyle }} {...props} />;
    // };

    useEffect(() => {
        if (ClearFormSet?.url === "machine_add" && ClearFormSet?.action === 200) {
            navigate('/machine-management')
        }
        setTimeout(() => {
            dispatch(ClearFormSetFutios({
                url: "",
                action: ""
            }))
        }, 1000)
    }, [ClearFormSet, dispatch, navigate])

    useEffect(() => {
        if (ClearFormSet?.url === "machine_edit" && ClearFormSet?.action === 200) {
            navigate('/machine-management')
        }
        setTimeout(() => {
            dispatch(ClearFormSetFutios({
                url: "",
                action: ""
            }))
        }, 1000)
    }, [ClearFormSet, navigate, dispatch])

    useState(() => {
        if (LocationRoute === "edit-machine") {
            setCreateMachine({
                ...createMachine,
                machineId: machineListData?.machine_name,
                orgId: machineListData?.org_id,
                address: machineListData?.machine_address,
                doorNo: machineListData?.door_no,
                active: machineListData?.is_active
            })
        }
    }, [LocationRoute, id, machineListData])


    const handleSwitchChange = (checked) => {
        setCreateMachine({
            ...createMachine,
            active: checked
        })
    }

    function addMachine(e) {
        if (createMachine?.machineId !== "" && createMachine?.doorNo !== "" && createMachine?.orgId !== "") {
            if (LocationURL === "/add-machine") {
                let param = {
                    "transmit": "broadcast",
                    "url": "machine_add",
                    "request": {
                        "machine_name": createMachine?.machineId,
                        "org_id": createMachine?.orgId,
                        "machine_address": createMachine?.address,
                        "door_no": createMachine?.doorNo,
                        "is_active": createMachine?.active
                    },
                }
                wsSend_request(websocket, param)
            } else {
                let param = {
                    "transmit": "broadcast",
                    "url": "machine_edit",
                    "request": {
                        "id": machineListData?.id,
                        "machine_name": createMachine?.machineId,
                        "org_id": createMachine?.orgId,
                        "machine_address": createMachine?.address,
                        "door_no": createMachine?.doorNo,
                        "is_active": createMachine?.active
                    },
                }
                wsSend_request(websocket, param)
            }
        } else {
            CheckValid(createMachine?.machineId, { type: 'MachineName', error: setErrorMachineId })
            CheckValid(createMachine?.doorNo, { type: 'DoorNo', error: setErrorDoorNo })
            CheckValid(createMachine?.orgId, { type: 'OrgId', error: setErrorOrgId })
            CheckValid(createMachine?.address, { type: 'Address', error: setAddressError })
        }
    }
    return (
        <>
            <div style={{ margin: "6px" }} className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-5">
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Machine Id <span className="text-danger">*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Machine Id"
                                            value={createMachine?.machineId}
                                            onKeyUp={(e) => CheckValid(e.target.value, { type: 'MachineName', error: setErrorMachineId })}
                                            onKeyDown={EmptySpaceFieldValid}
                                            onChange={(e) => {
                                                const input = e.target.value.replace(/\D/g, ''); 
                                                setCreateMachine({ ...createMachine, machineId: input });
                                            }}                                        />
                                        {errorMachineId && <span className="text-danger">{errorMachineId}</span>}
                                    </Form.Group>
                                </div>
                                <div className="col-5">
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Org Id <span className="text-danger">*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Org Id"
                                            value={createMachine?.orgId}
                                            onKeyUp={(e) => CheckValid(e.target.value, { type: 'OrgId', error: setErrorOrgId })}
                                            onKeyDown={EmptySpaceFieldValid}
                                            onChange={(e) => {
                                                const input = e.target.value.replace(/\D/g, ''); 
                                                setCreateMachine({ ...createMachine, orgId: input });
                                            }} 
                                        />
                                        {errorOrgId && <span className="text-danger">{errorOrgId}</span>}
                                    </Form.Group>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="row ">
                                <div className="col-5">
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Machine Address<span className="text-danger">*</span></Form.Label>
                                        <Form.Control
                                            style={{ height: "95px" }}
                                            type="text"
                                            as="textarea" rows={3}
                                            value={createMachine?.address}
                                            onChange={(e) => setCreateMachine({ ...createMachine, address: e.target.value })}
                                            onKeyUp={(e) => CheckValid(e.target.value, { type: 'Address', error: setAddressError })}
                                            onKeyDown={EmptySpaceFieldValid}
                                            placeholder="Enter Address"
                                        />
                                        {errorAddess !== "" && <span className="text-danger">{errorAddess}</span>}
                                    </Form.Group>
                                </div>
                                <div className="col-5">
                                    <Form.Group className="mb-2" >
                                        <Form.Label>Door No <span className="text-danger">*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={createMachine?.doorNo}
                                            placeholder="Enter Door No"
                                            onKeyUp={(e) => CheckValid(e.target.value, { type: 'DoorNo', error: setErrorDoorNo })}
                                            onKeyDown={EmptySpaceFieldValid}
                                            onChange={(e) => {
                                                const input = e.target.value.replace(/\D/g, ''); 
                                                setCreateMachine({ ...createMachine, doorNo: input });
                                            }}                                         />
                                        {errorDoorNo && <span className="text-danger">{errorDoorNo}</span>}
                                    </Form.Group>
                                    <span>
                                        <Form.Label className="mb-0">Status</Form.Label>
                                        <Form.Check
                                            type="switch"
                                            id="custom-switch"
                                            defaultChecked={createMachine?.active}
                                            onChange={(e) => handleSwitchChange(e.target.checked)}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-10">
                        <button id="button" className="pt-2  pe-3 pb-2 float-end mb-3" style={{width:"49%"}} type="submit" onClick={addMachine}>
                            {LocationRoute === "edit-machine" ? "Update Machine" : "Add Machine"}
                        </button>
                    </div>
                    </div>
            </div >
        </>
    );
};

export default CreateMachine;
