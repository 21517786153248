import React, { useContext, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import 'moment-timezone';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useSelector } from "react-redux";
import moment from "moment";
import 'moment-timezone';
import CommonPagination from "../commonModel/commonPagination";
import { WebSocketContext } from "../../App";
import wsSend_request from "../../Api/ws/ws_request";

const ContactUs = () => {
  const { search, contactList } = useSelector((state) => state.adminReducers);
  const [currentPage, setCurrentPage] = useState(1);
  const { websocket } = useContext(WebSocketContext);

  const renderTrack = ({ style, ...props }) => {
    const trackStyle = {
      display: "none"
    };
    return <div style={{ ...style, ...trackStyle }} {...props} />;
  };

  const CommonDateTime = (date, format, method) => {
    if (date !== null) {
      const currentTimezone = moment.tz.guess();
      return moment(date).tz(currentTimezone).format(format);
    } else {
      return method === "show" ? "-" : null;
    }
  };

  useEffect(() => {
    let param = {
      "transmit": "broadcast",
      "url": "contact_list",
      "request": {
        "date": "",
        "limit": "20",
        "page_no": search ? 1 : currentPage,
        "search": search ? search : "",
        "user_type": ""
      }
    }
    wsSend_request(websocket, param)
  }, [websocket, currentPage, search])

  const currentFunction = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div style={{ margin: "2px" }} className="fixTableHead m-2">
        <Scrollbars
          style={{ height: contactList?.pagination?.total_records > 20 ? "calc(100vh - 160px)" : "calc(100vh - 160px)" }}
          renderView={props => <div {...props} className="view" />}
          renderTrackHorizontal={renderTrack}
          className="ScrollbarsSidebar"
        >
          <Table responsive style={{ borderTopLeftRadius: "19px", borderTopRightRadius: "19px" }}>
            <thead style={{ position: "sticky", top: "0px" }}>
              <tr className="user-management-table-heading">
                <th>Email</th>
                <th>Description</th>
                <th>Mobile</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody id="contact-us-table">
              {contactList?.list.length > 0 ? (
                contactList.list.map((contact) => (
                  <tr key={contact.id} className="user-management-table-detail">
                    <td style={{ cursor: "default" }}>{contact.email}</td>
                    <td>   <form action="">
                      <textarea defaultValue={contact.description} readOnly></textarea>
                    </form></td>
                    <td style={{ cursor: "default" }}>{contact.mobile || "-"}</td>
                    <td style={{ cursor: "default" }}>{CommonDateTime(contact.created_at, "DD MMM YYYY", "show")}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4">Data not found</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Scrollbars>
        {contactList?.pagination?.total_records > 20 && (<CommonPagination
          currentPage={currentPage}
          paginationLength={contactList?.pagination}
          currentFunction={currentFunction}
        />)}
      </div>
    </>
  );
};

export default ContactUs;
