import React, { useEffect, useState } from "react";
import 'moment-timezone';
import { Form } from "react-bootstrap";
import EmptySpaceFieldValid from "../../CheckValid/EmptySpaceValid";
import CheckValid from "../../CheckValid/checkValid";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import wsSend_request from "../../Api/ws/ws_request";
import { useContext } from "react";
import { WebSocketContext } from "../../App";
import { useDispatch, useSelector } from "react-redux";
import { ClearFormSetFutios } from "../../redux/actions/adminAction";

import {
    EditorState,
    convertToRaw,
    convertFromHTML,
    ContentState
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";


const CreateFaq = () => {
    const LocationRoute = useLocation()?.pathname?.split("/")[1];
    const { websocket } = useContext(WebSocketContext);
    const LocationURL = useLocation().pathname;
    const id = useParams()?.id;
    const { ClearFormSet, faqList } = useSelector((state) => state.adminReducers);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [createFaq, setCreateFaq] = useState({
        question:"",
        answer: "",
    })
    const [questionEditorState, setAnsEditorState] = useState(
        EditorState.createEmpty()
    );
    const [charCount, setCharCount] = useState("")
    const PageListData = faqList?.list?.filter((data) => data.id === id)[0]

    const [errorquestion_name, seterrorquestion_name] = useState("")
    const [erroranswer, seterroranswer] = useState("")

    useEffect(() => {
        if (ClearFormSet?.url === "faq_add" && ClearFormSet?.action === 200) {
            navigate('/faq')
        }
        setTimeout(() => {
            dispatch(ClearFormSetFutios({
                url: "",
                action: ""
            }))
        }, 1000)
    }, [ClearFormSet, dispatch, navigate])

    useEffect(() => {
        if (ClearFormSet?.url === "faq_edit" && ClearFormSet?.action === 200) {
            navigate('/faq')
        }
        setTimeout(() => {
            dispatch(ClearFormSetFutios({
                url: "",
                action: ""
            }))
        }, 1000)
    }, [ClearFormSet, navigate, dispatch])


    useEffect(() => {
        if (PageListData?.answer !== null && LocationRoute === "edit-faq") {
            let defaultquestionContent = PageListData?.answer;
            const questionContentState = ContentState.createFromBlockArray(
                convertFromHTML(defaultquestionContent)
            );
            const questionEditorState = EditorState.createWithContent(questionContentState);
            setAnsEditorState(questionEditorState);


            const contentStateText = questionContentState.getPlainText();

            setCreateFaq(pre => ({
                ...pre,
                answer: contentStateText
            }))
        }
    }, [PageListData, LocationRoute]);

    useState(() => {
        if (PageListData && LocationRoute === "edit-faq") {
            setCreateFaq({
                ...createFaq,
                question: PageListData?.question,
            })
        }
    }, [LocationRoute, id, PageListData])


    const [faqAnswer, setFaqAnswer] = useState("")
    console.log(faqAnswer)
    const maxDescLength = 1024
    const onAnswerEditorChange = (newEditorState) => {
        if (charCount < maxDescLength) {
            setAnsEditorState(newEditorState);
            const contentState = newEditorState.getCurrentContent();
            const plainText = contentState.getPlainText("");
            const characterCount = plainText.length;
            setCharCount(characterCount);
            const contentAsRaw = convertToRaw(contentState);
            const htmlContent = draftToHtml(contentAsRaw);
            const styledHtmlContent = `<div style="font-family: sans-serif;">${htmlContent}</div>`;
            setFaqAnswer(styledHtmlContent);
            setCreateFaq((prevInputValue) => ({
                ...prevInputValue,
                answer: styledHtmlContent,
            }));
        }
    };


    function addFaq(e) {
        e.preventDefault()
        if (createFaq?.question !== "" && createFaq?.answer !== "") {
            if (LocationURL === "/add-faq") {
                let param = {
                    "transmit": "broadcast",
                    "url": "faq_add",
                    "request": {
                        "question": createFaq?.question,
                        "answer": createFaq?.answer,
                    },
                }
                wsSend_request(websocket, param)
            } else {
                let param = {
                    "transmit": "broadcast",
                    "url": "faq_edit",
                    "request": {
                        "faq_id": PageListData?.id,
                        "question": createFaq?.question,
                        "answer": createFaq?.answer,
                    },
                }
                wsSend_request(websocket, param)
            }
        } else {
            CheckValid(createFaq?.question, { type: 'question', error: seterrorquestion_name })
            CheckValid(createFaq?.answer, { type: 'answer', error: seterroranswer })
        }
    }
    console.log(createFaq)
    return (
        <>
            <div style={{ margin: "6px" }} className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-10">
                                <Form.Group className="mb-3" >
                                    <Form.Label>Question<span className="text-danger">*</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Question"
                                        value={createFaq?.question}
                                        onKeyUp={(e) => CheckValid(e.target.value, { type: 'question', error: seterrorquestion_name })}
                                        onKeyDown={EmptySpaceFieldValid}
                                        onChange={(e) => setCreateFaq({ ...createFaq, question: e.target.value })}
                                    />
                                    {errorquestion_name && <span className="text-danger">{errorquestion_name}</span>}
                                </Form.Group>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-10">
                                <Form.Group >
                                    <Form.Label>Answer<span className="text-danger">*</span></Form.Label>
                                    <Editor
                                        editorState={questionEditorState}
                                        onEditorStateChange={onAnswerEditorChange}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        wrapperStyle={{
                                            width: "97%",
                                            border: "1px solid #ccc",
                                            backgroundColor: "white",
                                            padding: "10px",
                                            borderRadius: "4px",
                                        }}
                                        editorStyle={{
                                            minHeight: "100px",
                                            border: "1px solid #ccc",
                                            padding: "10px",
                                            borderRadius: "4px",
                                            fontFamily: "sans-serif",
                                        }}
                                        toolbar={{
                                            options: [
                                                "inline",
                                                "blockType",
                                                "fontSize",
                                                "list",
                                                "textAlign",
                                                "colorPicker",
                                                "link",
                                                "emoji",
                                                "history",
                                            ],
                                            inline: {
                                                inDropdown: false,
                                                options: [
                                                    "bold",
                                                    "italic",
                                                    "underline",
                                                    "strikethrough",
                                                    "superscript",
                                                    "subscript",
                                                ],
                                            },
                                            list: {
                                                inDropdown: false,
                                                options: ["unordered", "ordered"],
                                            },
                                            textAlign: {
                                                inDropdown: true,
                                            },
                                            link: { inDropdown: true },
                                            history: { inDropdown: false },
                                        }}
                                    />
                                    {erroranswer !== "" && <span className="text-danger">{erroranswer}</span>}
                                </Form.Group>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-10">
                        <button id="button"  className="pt-2 ps-3 pe-3 pb-2 float-end mb-3" type="submit" onClick={addFaq}>
                            {LocationRoute === "edit-faq" ? "Update FAQ" : "Add FAQ"}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreateFaq;

