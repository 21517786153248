/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import 'moment-timezone';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useDispatch, useSelector } from "react-redux";
import 'moment-timezone';
import CommonPagination from "../commonModel/commonPagination";
import { WebSocketContext } from "../../App";
import wsSend_request from "../../Api/ws/ws_request";
import { useNavigate } from "react-router-dom";
import CommonewModel from "../commonModel/commonModel";
import { ClearFormSetFutios } from "../../redux/actions/adminAction";

const BenefitManagement = () => {
    const { benefitList, ClearFormSet } = useSelector((state) => state.adminReducers);
    const [currentPage, setCurrentPage] = useState(1);
    const { websocket } = useContext(WebSocketContext);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [showModalNew, setShowModalNew] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });
    const renderTrack = ({ style, ...props }) => {
        const trackStyle = {
            display: "none"
        };
        return <div style={{ ...style, ...trackStyle }} {...props} />;
    };

    const openModal = (id) => {
        setShowModalNew({
            ...showModalNew,
            open: !showModalNew?.open,
            title: "Create New Box",
            subtitle: "Create New Model",
            modalType: "delete-popup-benefit",
            Data: id
        })
    };


    function callFaqList() {
        let param = {
            "transmit": "broadcast",
            "url": "benefit_list",
            "request": {
                "limit": "20",
                "page_no": currentPage,
            }
        }
        wsSend_request(websocket, param)
    }

    useEffect(() => {
        callFaqList()
    }, [websocket, currentPage])

    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        if (ClearFormSet?.url === "benefit_edit" && ClearFormSet?.action === 200) {
            callFaqList()
        }
        setTimeout(() => {
            dispatch(ClearFormSetFutios({
                url: "",
                action: ""
            }))
        }, 1000)
    }, [ClearFormSet, dispatch])
    
    useEffect(() => {
        if (ClearFormSet?.url === "benefit_delete" && ClearFormSet?.action === 200) {
            callFaqList()
        }
        setTimeout(() => {
            dispatch(ClearFormSetFutios({
                url: "",
                action: ""
            }))
        }, 1000)
    }, [ClearFormSet, dispatch])

    function htmlToText(html) {
        var doc = new DOMParser().parseFromString(html, "text/html");
        return doc.body.textContent || "";
    }
    console.log(benefitList)
    return (
        <>
            <div style={{ margin: "2px" }} className="fixTableHead">
                <Scrollbars
                    style={{ height: benefitList?.pagination?.total_records > 20 ? "calc(100vh - 160px)" : "calc(100vh - 125px)" }}
                    renderView={props => <div {...props} className="view" />}
                    renderTrackHorizontal={renderTrack}
                    className="ScrollbarsSidebar"
                >
                    <Table responsive style={{ borderTopLeftRadius: "19px", borderTopRightRadius: "19px" }}>
                        <thead style={{ position: "sticky", top: "0px" }}>
                            <tr className="user-management-table-heading">
                                <th>Question</th>
                                <th>Answer</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {benefitList?.list.map((faq) => (
                                <tr key={faq.id} className="user-management-table-detail">
                                    <td style={{ cursor: "default"}}>{faq.question}</td>
                                    <td><form action="">
                                        <textarea defaultValue={htmlToText(faq.answer)} readOnly style={{height:'40px'}}></textarea>
                                    </form></td>
                                    {/* <td style={{ cursor: "default" }}>{htmlToText(faq.answer)}</td> */}
                                    <td style={{ cursor: "default" }}>
                                        <span className="detail-icon" onClick={() => navigate("/edit-benefit/" + faq.id)}>
                                            <svg height="22" viewBox="0 0 50 50" width="22" xmlns="http://www.w3.org/2000/svg"><g id="Icons"><path d="m43.4 6.6a5.44 5.44 0 0 0 -7.71 0l-19.57 19.62a1 1 0 0 0 -.28.59l-.84 7.07a1 1 0 0 0 .28.82 1 1 0 0 0 .72.3h.11l7.13-.74a1 1 0 0 0 .6-.29l19.56-19.63a5.49 5.49 0 0 0 0-7.74zm-20.72 25.69-5.54.59.64-5.49 15.46-15.5 4.89 4.9zm16.86-16.91-4.89-4.91 2.45-2.47a3.53 3.53 0 0 1 4.9 0 3.47 3.47 0 0 1 0 4.9z" /><path d="m39 38a5 5 0 0 1 -5 5h-22a5 5 0 0 1 -5-5v-22a5 5 0 0 1 5-5h15v-2h-15a7 7 0 0 0 -7 7v22a7 7 0 0 0 7 7h22a7 7 0 0 0 7-7v-15h-2z" /></g></svg>
                                        </span>
                                        <span className="detail-icon ms-2" onClick={() => openModal(faq.id)}>

                                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" height="22" x="0px" y="0px"
                                                viewBox="0 0 512 512">
                                                <g>
                                                    <g>
                                                        <path d="M436,60h-75V45c0-24.813-20.187-45-45-45H196c-24.813,0-45,20.187-45,45v15H76c-24.813,0-45,20.187-45,45
			c0,19.928,13.025,36.861,31.005,42.761L88.76,470.736C90.687,493.875,110.385,512,133.604,512h244.792
			c23.22,0,42.918-18.125,44.846-41.271l26.753-322.969C467.975,141.861,481,124.928,481,105C481,80.187,460.813,60,436,60z M181,45
			c0-8.271,6.729-15,15-15h120c8.271,0,15,6.729,15,15v15H181V45z M393.344,468.246c-0.643,7.712-7.208,13.754-14.948,13.754
			H133.604c-7.739,0-14.305-6.042-14.946-13.747L92.294,150h327.412L393.344,468.246z M436,120H76c-8.271,0-15-6.729-15-15
			s6.729-15,15-15h360c8.271,0,15,6.729,15,15S444.271,120,436,120z"/>
                                                    </g>
                                                </g>
                                                <g>
                                                    <g>
                                                        <path d="M195.971,436.071l-15-242c-0.513-8.269-7.67-14.558-15.899-14.043c-8.269,0.513-14.556,7.631-14.044,15.899l15,242.001
			c0.493,7.953,7.097,14.072,14.957,14.072C189.672,452,196.504,444.684,195.971,436.071z"/>
                                                    </g>
                                                </g>
                                                <g>
                                                    <g>
                                                        <path d="M256,180c-8.284,0-15,6.716-15,15v242c0,8.284,6.716,15,15,15s15-6.716,15-15V195C271,186.716,264.284,180,256,180z" />
                                                    </g>
                                                </g>
                                                <g>
                                                    <g>
                                                        <path d="M346.927,180.029c-8.25-0.513-15.387,5.774-15.899,14.043l-15,242c-0.511,8.268,5.776,15.386,14.044,15.899
			c8.273,0.512,15.387-5.778,15.899-14.043l15-242C361.483,187.659,355.196,180.541,346.927,180.029z"/>
                                                    </g>
                                                </g>
                                            </svg>

                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Scrollbars>
                {benefitList?.pagination?.total_records > 20 && (<CommonPagination
                    currentPage={currentPage}
                    paginationLength={benefitList?.pagination}
                    currentFunction={currentFunction}
                />)}
            </div>
            {showModalNew?.open && (<CommonewModel setShowModalNew={setShowModalNew} showModalNew={showModalNew} />)}
        </>
    );
};

export default BenefitManagement;
