/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

export default function Images(props) {
  const { accessToken } = useSelector((state) => state.adminReducers);
  const [imageSrc, setImageSrc] = useState(null);
  const [zoomedImage, setZoomedImage] = useState(null);

  useEffect(() => {
    // const img = new Image();
    // if(!props?.auth || window.location.hostname !== "localhost"){
    //   img.src = props.src;

    //   img.onload = () => {
    //     setImageSrc(img.src);
    //   };
    // }else if(accessToken && window.location.hostname == "localhost"){
    fetchData()
    // }
    // return () => {
    //   img.src = '';
    // };
  }, [props.src]);

  const fetchData = async () => {
    try {
      const response = await fetchImageWithAuthorization(`${process.env.REACT_APP_BASE_URL}file/view/file/${props.src}`);
      if (response?.status === 200) {
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        console.log("url", url)
        setImageSrc(url);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      // Handle the error as needed, such as displaying an error message to the user
    }
  };

  const fetchImageWithAuthorization = async (url) => {
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${accessToken}`);
    const requestOptions = {
      method: 'GET',
      headers: headers,
      redirect: 'follow'
    };
    return fetch(url, requestOptions);
  };

  const handleImageClick = () => {
    setZoomedImage(imageSrc);
  };

  const handleOverlayClick = () => {
    setZoomedImage(null);
  };
  
  return (
    <picture style={{ position: "relative" }} className={`picture-opacity-1 ${props?.imageClassName}`}>
      {imageSrc && <source type={props.type} srcSet={imageSrc} />}
      {imageSrc && (
        <img
          src={imageSrc}
          alt={props.alt}
          loading="eager"
          className={props.className}
          width={props?.width}
          height={props?.height}
          onClick={props.alt === 'Document' ? handleImageClick : null}
          style={{ cursor: props.alt === 'Document' ? 'zoom-in' : 'default' }}
          />
      )}
      {zoomedImage && (
          <div className="zoom-overlay" onClick={handleOverlayClick}>
            <img src={zoomedImage} alt="Zoomed Document" />
          </div>
        )}
      {/* <span style={{color:"red", right: "2%",  position: "absolute"}}> 
        X
    </span> */}
    </picture>
  );
}

Images.propTypes = {
  type: PropTypes.any,
  src: PropTypes.any,
  alt: PropTypes.any,
  className: PropTypes.any,
  imageClassName: PropTypes.any,
  width: PropTypes.any,
  height: PropTypes.any,
  auth: PropTypes.bool,
};

Images.defaultProps = {
  type: 'image/webp',
  src: '',
  alt: '',
  className: '',
  imageClassName: '',
  width: 0,
  height: 0,
  auth: false,
};