/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useEffect, useContext, useState } from "react";
import { Table, Form,  Collapse } from "react-bootstrap";
import 'moment-timezone';
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import { useDispatch, useSelector } from "react-redux";
import CommonPagination from "../commonModel/commonPagination";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { ClearFormSetFutios } from "../../redux/actions/adminAction";
import Images from "../../Api/Images";
import { CommonDateTime } from "../../Api/commonDateTime";
import CommonewModel from "../commonModel/commonModel";
const UserManagement = () => {
  const { websocket } = useContext(WebSocketContext);
  const { userList, search, listStatus, ClearFormSet } = useSelector((state) => state.adminReducers);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch()
  const [activeTableDetails, setActiveTableDetails] = useState("");
  const [zoomedImage, setZoomedImage] = useState(null);
  
  const [showModalNew, setShowModalNew] = useState({
    open: false,
    title: "",
    modalType: "",
    Data: {}
});

  const currentFunction = (page) => {
    setCurrentPage(page);
  };

  const renderTrack = ({ style, ...props }) => {
    const trackStyle = {
      display: "none"
    };
    return <div style={{ ...style, ...trackStyle }} {...props} />;
  };



  useEffect(() => {
    callUserList()
  }, [websocket, currentPage, search, listStatus]);

  function callUserList() {
    let param = {
      "transmit": "broadcast",
      "url": "user_list",
      "request": {
        "status": listStatus === undefined || listStatus === "" ? "" : parseInt(listStatus),
        "limit": "20",
        "page_no": search ? 1 : currentPage,
        "search": search ? search : "",
        "order_by": "-updated_at"
      }
    }
    wsSend_request(websocket, param)
  }
  function handleSwitchChange(id, isChecked) {
    let param = {
      "transmit": "broadcast",
      "url": "user_status_update",
      "request": {
        "status": isChecked,
        "user_id": id
      }
    }
    wsSend_request(websocket, param)
  }

  useEffect(() => {
    if (ClearFormSet?.url === "user_status_update" && ClearFormSet?.action === 200) {
      callUserList()
    }
    setTimeout(() => {
      dispatch(ClearFormSetFutios({
        url: "",
        action: ""
      }))
    }, 1000)
  }, [ClearFormSet, dispatch])

    const openModal = (Amt,user_id,status) => {
      setShowModalNew({
          ...showModalNew,
          open: !showModalNew?.open,
          title: "Create New Box",
          subtitle: "Create New Model",
          modalType: status,
          Data: {"Amt":Amt,"user_id":user_id}
      })
  };


  const handleOverlayClick = () => {
    setZoomedImage(null);
  };

  const ViewMoreDetails = (id) => {
    setActiveTableDetails(id);
  };

  const ElseMoreDetails = () => {
    setActiveTableDetails("");
  };

  return (
    <>
      <div style={{ margin: "2px" }} className="fixTableHead ">
        <Scrollbars
          style={{ height: userList?.pagination?.total_records > 20 ? "calc(100vh - 160px)" : "calc(100vh - 125px)" }}
          renderView={props => <div {...props} className="view" />}
          renderTrackHorizontal={renderTrack}
          className="ScrollbarsSidebar"
        >
          <Table responsive style={{ width: "" }}>
            <thead style={{ position: "sticky", top: "0px" }}>
              <tr className="user-management-table-heading">
                <th></th>
                <th>Full Name</th>
                <th>User Id</th>
                <th >Email Address</th>
                <th >Mobile Number</th>
                <th>Verification</th>
                <th >Status</th>
                <th>Assign Credit</th>
              </tr>
            </thead>
            <tbody>
              {userList?.list.length > 0 ? (
                userList.list.map((user) => (
                  <><tr key={user.id} className="user-management-table-detail">
                    <td style={{ width: '10px' }}>
                      <svg
                        onClick={() => activeTableDetails === user?.id ? ElseMoreDetails() : ViewMoreDetails(user?.id)}
                        aria-expanded={activeTableDetails === user?.id ? true : false}
                        width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.596591 0.272727H11.5057L6.05114 9.54545L0.596591 0.272727Z" fill="#515151" />
                      </svg>
                    </td>
                    <td style={{ cursor: "default", whiteSpace: "normal !important" }}>{user.username || "-"}</td>
                    <td>{user.user_code ? user.user_code : "-"}</td>
                    <td style={{ cursor: "default", whiteSpace: "normal !important" }}>{user.email || "-"}</td>
                    <td style={{ cursor: "default", whiteSpace: "normal !important" }}>{user.phone || "-"}</td>
                    {/* <td style={{ cursor: "default", whiteSpace: "normal !important" }}>{user.nric_no || "-"}</td> */}
                    {/* <td style={{ cursor: "default", whiteSpace: "normal !important" }}>{user.user_balance}</td> */}
                    {user.is_verified ? <td style={{ color: "#00AE5B" }}>Verified</td> : <td style={{ color: "#FF0000" }}>Not Verified</td>}
                    <td style={{ cursor: "default", whiteSpace: "normal !important" }}>
                      <span>
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          defaultChecked={user.is_active}
                          onChange={(e) => handleSwitchChange(user.id, e.target.checked)} />
                      </span>
                    </td>
                    <td style={{ color: "blue" }} onClick={()=>openModal(user.user_balance,user.id,"Assign Credit")}>0.00</td>
                  </tr>
                    <Collapse in={activeTableDetails === user?.id ? true : false}>
                      <tr className="tablecollapsewraps" id={"tablecollpase" + user?.id}>
                        <td colSpan="8">
                          <table className="table border">
                            <tbody style={{ backgroundColor: "#F3F3F3" }}>
                              <div className="row">
                                <div className="col-12">
                                  <div className="row" id="collapse-heading">
                                    <div className="col-5 bg-white border ms-5 p-2">
                                      <span className="my-3" >Document</span>
                                      <span className="mx-3 mt-1 mb-1 float-end"><Images src={user.document_front} height={56} width={100} alt="Document" /></span>
                                      <span className="ms-4 mt-1 mb-1 float-end" ><Images src={user.document_back} height={56} width={100} alt="Document"/></span>
                                    </div>
                                    {zoomedImage && (
                                      <div className="zoom-overlay" onClick={handleOverlayClick}>
                                        <img src={zoomedImage} alt="Zoomed Document" />
                                      </div>
                                    )}
                                    <div className="col-5 border bg-white p-2 text-center">USER ID:{user.user_code ? user.user_code : "-"}</div>
                                    <div className="col-5 border bg-white ms-5 p-2">
                                      <div><span> My Balance</span> </div>
                                      <span>(Refund Unutilized {CommonDateTime(user.user_balance_expire_date, "DD MMM YYYY", "show")})</span><span className="ms-5">RM {user.user_balance}</span>
                                    </div>
                                    <div className="col-5 border bg-white p-2 text-center pt-3">
                                      {user.is_verified ? <span style={{ color: "#00AE5B" }}>Verified</span> : <span style={{ color: "#FF0000" }}>Not Verified</span>}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* <tr className="user-management-table-detail">
                                <th style={{ width: "20%", color: "black" }}>Document</th>
                                <td style={{ cursor: "default", whiteSpace: "normal !important" }}>
                                  <span><Images src={user.document_front} height={100} width ={200} alt="Document"/></span>
                                  <span className="ms-4"><Images src={user.document_back} height={100} width ={200} alt="Document" /></span>
                                </td>
                              </tr>
                              <tr className="user-management-table-detail">
                                <th style={{ width: "20%", color: "black" }}>Wallet Amount</th>
                                <td style={{ cursor: "default", whiteSpace: "normal !important" }}>{user.user_balance}</td>
                              </tr> */}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </Collapse>
                  </>
                ))
              ) : (
                <tr>
                  <td colSpan="8">Data not found</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Scrollbars>
        {userList?.pagination?.total_records > 20 && (<CommonPagination
          currentPage={currentPage}
          paginationLength={userList?.pagination}
          currentFunction={currentFunction}
        />)}
            {showModalNew?.open && (<CommonewModel setShowModalNew={setShowModalNew} showModalNew={showModalNew} />)}
      </div>
    </>
  );
};

export default UserManagement;
