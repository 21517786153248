import React, { useState } from "react";
import ReportTransaction from "./ReportTransaction";
import ContactUs from "../ReportManagement/ReportManagement";
import AboutPage from "./about_us_page";
import TermOfPage from "./term_of_use_page";
import PrivacyPolicy from "./privacy_policy_page";
import DocumentType from "./documnet_type";
// import { InputGroup, Form } from 'react-bootstrap'
const PagesTab = () => {
    const [activeTab, setActiveTab] = useState("about-us");

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
        localStorage.setItem("activeTab", tabName);
    };

    return (
        <>
            <div className="row">
                <div className="col-12 ">
                    <div className="row p-1 Tab mt-2">
                        <div className="col-2">
                            <span
                            style={{cursor:"pointer"}}
                                onClick={() => handleTabClick("about-us")}
                                className={
                                    activeTab === "about-us"
                                        ? "active mx-3 mb-3 pb-3"
                                        : "mx-3 mb-3 pb-2"
                                }
                            >
                                About Us
                            </span>
                        </div>
                        <div className="col-2">
                            <span
                            style={{cursor:"pointer"}}
                                onClick={() => handleTabClick("privacy-policy")}
                                className={
                                    activeTab === "privacy-policy"
                                        ? "active mx-3 mb-3 pb-3"
                                        : "mx-3 mb-3 pb-2"
                                }
                            >
                                Privacy Policy
                            </span>
                        </div>
                        <div className="col-2">
                            <span
                            style={{cursor:"pointer"}}
                                onClick={() => handleTabClick("term-of-use")}
                                className={
                                    activeTab === "term-of-use"
                                        ? "active mx-3 mb-3 pb-3"
                                        : "mx-3 mb-3 pb-2"
                                }
                            >
                                Terms of Use
                            </span>
                        </div>
                        <div className="col-2">

                            <span
                            style={{cursor:"pointer"}}
                                onClick={() => handleTabClick("report-transaction")}
                                className={
                                    activeTab === "report-transaction"
                                        ? "active  mb-2 pb-3"
                                        : " mb-3 pb-2"
                                }
                            >
                                Report Transaction 
                            </span>
                        </div>
                        <div className="col-2">
                            <span
                            style={{cursor:"pointer"}}
                                onClick={() => handleTabClick("contact-us")}
                                className={
                                    activeTab === "contact-us"
                                        ? "active mx-3 mb-3 pb-3"
                                        : "mx-3 mb-3 pb-2"
                                }
                            >
                                Contact Us
                            </span>
                        </div>
                        <div className="col-2">
                            <span
                                style={{cursor:"pointer"}}
                                onClick={() => handleTabClick("document_type")}
                                className={
                                    activeTab === "document_type"
                                        ? "active mx-3 mb-3 pb-3"
                                        : "mx-3 mb-3 pb-2"
                                }
                            >
                                Document Type
                            </span>
                        </div>
                        <span className="col-12 border-bottom " style={{ width: "98%", marginLeft: "1%" ,marginTop:"17px"}}></span>
                    </div>

                    {activeTab === "report-transaction" && <ReportTransaction />}
                    {activeTab === "contact-us" && <ContactUs />}
                    {activeTab === "about-us" && <AboutPage tabName={activeTab} />}
                    {activeTab === "term-of-use" && <TermOfPage tabName={activeTab} />}
                    {activeTab === "privacy-policy" && <PrivacyPolicy tabName={activeTab} />}
                    {activeTab === "document_type" && <DocumentType tabName={activeTab} />}

                </div>
            </div>
        </>
    );
};

export default PagesTab;
