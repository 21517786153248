import axios  from "axios";

const LogoutAPI = async (accessToken) => {
    const responce = axios.get(`${process.env.REACT_APP_BASE_URL}user/logout`, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + accessToken,
    }}).then(function (result) {
        return {
          ...result.data,
          status:200
        };
    }).catch(function (result) {
        return {
          ...result?.response?.data,
          status:result?.response?.status
        }
    });
    return responce;
};
  
export default LogoutAPI;