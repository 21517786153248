import React, { useContext, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useSelector } from "react-redux";
import CommonPagination from "../commonModel/commonPagination";
import { WebSocketContext } from "../../App";
import wsSend_request from "../../Api/ws/ws_request";
import { CommonDateTime } from "../../Api/commonDateTime";

const Transaction = () => {
  const { search, transactionList, userId, formDate, toDate,userList } = useSelector((state) => state.adminReducers);
  const [currentPage, setCurrentPage] = useState(search ? 1 : 1); // Initialize currentPage based on search
  const { websocket } = useContext(WebSocketContext);
  const [activeTableDetails, setActiveTableDetails] = useState(null); // Initialize as null

  // Effect hook with consistent dependencies
  useEffect(() => {
    const param = {
      "transmit": "broadcast",
      "url": "transaction_list",
      "request": {
        "limit": "20",
        "page_no": search ? 1 : currentPage,
        "search": search || "",
        "order_by": "-updated_at",
        "user_id": userId || "" 
      }
    };

    if (formDate && toDate) {
      param.request.from_date = CommonDateTime(formDate, "YYYY-MM-DD", "hide");
      param.request.to_date = CommonDateTime(toDate, "YYYY-MM-DD", "hide");
    }

    wsSend_request(websocket, param);
  }, [websocket, currentPage, search, userId, formDate, toDate]);

  // Handler for page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

function tcnStatus(status){
   if (status==="credit_wallet"){
    return{
      name:"Top Up Transactions",
      color:"#000000"
    }
   }
   if (status==="debit_wallet"){
    return{
      name:"Purchase Transactions",
      color:"#000000"
    }
   }
   if (status==="Refund (Manually)"){
    return{
      name:"Refund Transactions",
      color:"#5666B9"
    }
   }
   if (status==="transfer_credit_wallet" || status==="transfer_debit_wallet" || status ==="Transfer"){
    return{
      name:"Transfer Transactions",
      color:"#00AE5B"
    }
   }
   if (status==="Refund (Un-utlized)"){
    return{
      name:"Unutilised Transactions",
      color:"#000000"
    }
   }
   if(status==="debit_order"){
    return{
      name:"Order Transactions",
      color:"#F5A200"
    }
   }
}

const UserDetail = (id) => {
  const userInf = userList?.list?.filter((user) => user?.id === id)[0]
  return {
    userName: userInf?.username,
    useEmail: userInf?.email,
    mobileNUmber: userInf?.phone,
    user_code:userInf?.user_code
  }
}

  // Toggle function for table details
  const toggleDetails = (id) => {
    setActiveTableDetails(prevId => (prevId === id ? null : id));
  };  

  return (
    <div style={{ margin: "2px" }} className="fixTableHead">
      <Scrollbars
        style={{ height: transactionList?.pagination?.total_records > 20 ? "calc(100vh - 200px)" : "calc(100vh - 170px)" }}
        renderTrackHorizontal={() => <div style={{ display: "none" }} />}
        className="ScrollbarsSidebar"
      >
        <Table responsive style={{ borderTopLeftRadius: "19px", borderTopRightRadius: "19px" }}>
          <thead style={{ position: "sticky", top: "0px" }}>
            <tr className="user-management-table-heading">
              <th></th>
              <th>Date</th>
              <th>Transaction Type</th>
              <th>Arvgo Transaction Id</th>
              <th>Amount</th>
              <th>Status</th>
              <th>user ID</th>
            </tr>
          </thead>
          <tbody>
            {transactionList?.list.length > 0 ? (
              transactionList.list.map((transaction) => (
                <React.Fragment key={transaction.id}>
                  <tr className="user-management-table-detail">
                    <td style={{ width: '10px' }}>
                      <svg
                        onClick={() => toggleDetails(transaction?.id)}
                        aria-expanded={activeTableDetails === transaction?.id}
                        width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.596591 0.272727H11.5057L6.05114 9.54545L0.596591 0.272727Z" fill="#515151" />
                      </svg>
                    </td>
                        <td>{CommonDateTime(transaction.created_at, "DD MMM YYYY", "show")}<span className="ms-2">{CommonDateTime(transaction.created_at, "hh:mm A", "show")}</span></td>
                    <td>
                      <span style={{color:tcnStatus(transaction.trn_type)?.color}} >{tcnStatus(transaction.trn_type)?.name}</span></td>
                    <td>{transaction.transaction_id}</td>
                    <td>{`Rm ${transaction.total_amount || "-"}`}</td>
                    <td>{transaction.status.toUpperCase() || "-"}</td>
                    <td>{UserDetail(transaction?.user_id)?.user_code || "-"}</td>
                  </tr>
                  {activeTableDetails === transaction.id && (
                    <tr className="tablecollapsewraps" id={"tablecollapse" + transaction.id}>
                      <td colSpan="6">
                        <table className="table border">
                          <tbody>
                            <tr className="user-management-table-detail">
                              <th style={{ width: "20%", color: "black" }}>Notes</th>
                              <td>{transaction.notes || "-"}</td>
                            </tr>
                            <tr className="user-management-table-detail">
                              <th style={{ width: "20%", color: "black" }}>Previous Balance</th>
                              <td>{`Rm ${transaction.previous_balance || "-"}`}</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))
            ) : (
              <tr>
                <td colSpan="6">Data not found</td>
              </tr>
            )}
          </tbody>
        </Table>
      </Scrollbars>
      {transactionList?.pagination?.total_records > 20 && (
        <CommonPagination
          currentPage={currentPage}
          paginationLength={transactionList?.pagination}
          currentFunction={handlePageChange}
        />
      )}
    </div>
  );
};

export default Transaction;
