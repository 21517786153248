import React, { useContext, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import 'moment-timezone';
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import { useSelector } from "react-redux";
import { Scrollbars } from 'react-custom-scrollbars-2';
import CommonPagination from "../commonModel/commonPagination";
import { UserDetail } from "../commonModel/getuser";

const ReportTransactionList = () => {
  const { websocket } = useContext(WebSocketContext);
  const { search ,userId,reportMessageList,reportList} = useSelector((state) => state.adminReducers);
  const [currentPage, setCurrentPage] = useState(1)

  const renderTrack = ({ style, ...props }) => {
    const trackStyle = {
      display: "none"
    };
    return <div style={{ ...style, ...trackStyle }} {...props} />;
  };

  const currentFunction = (page) => {
    setCurrentPage(page);
  };

  const reportDetail = (id) => {
    const report = reportList?.list?.filter((report) => report?.id === id)[0]
    return {
      title: report?.title,
    }
  }


  useEffect(() => {
    let param = {
      "transmit": "broadcast",
      "url": "report_message_list",
      "request": {
        "limit": "20",
        "page_no": currentPage,
      }
    }
    wsSend_request(websocket, param)
  }, [websocket, currentPage, search,userId])


  return (
    <>
      <div style={{ margin: "2px" }} className="fixTableHead">
        <Scrollbars
          style={{ height: reportMessageList?.pagination?.total_records > 20 ? "calc(100vh - 200px)" : "calc(100vh - 160px)" }}
          renderView={props => <div {...props} className="view" />}
          renderTrackHorizontal={renderTrack}
          className="ScrollbarsSidebar"
        >
          <Table responsive style={{ borderTopLeftRadius: "19px", borderTopRightRadius: "19px" }}>
            <thead style={{ position: "sticky", top: "0px" }}>
              <tr className="user-management-table-heading">
                <th>User Name</th>
                <th>Mobile Number</th>
                <th>Topic</th>
                <th>Message</th>
              </tr>
            </thead>
            <tbody>
              {reportMessageList?.list.length > 0 ? (
                reportMessageList.list.map((report) => (
                  <tr key={report.id} className="user-management-table-detail">
                    <td style={{ cursor: "default" }}>{UserDetail(report?.user_id)?.userName || "-"}</td>
                    <td style={{ cursor: "default" }}>{UserDetail(report?.user_id)?.mobileNUmber || "-"}</td>
                    <td style={{ cursor: "default" }}>{reportDetail(report?.report_id)?.title}</td>
                    <td style={{ cursor: "default" }}>{report.message || "-"}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4">Data not found</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Scrollbars>
        {reportMessageList?.pagination?.total_records > 20 && (<CommonPagination
          currentPage={currentPage}
          paginationLength={reportMessageList?.pagination}
          currentFunction={currentFunction}
        />)}
      </div>
    </>
  );
};

export default ReportTransactionList;
