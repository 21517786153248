/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable eqeqeq */
import { Modal, Table } from "react-bootstrap"
import CommonLogout from "./commonLogout";
import { useDispatch, useSelector } from "react-redux";
import logoutimg from "../../assets/image/logoutimg.png"
import wsSend_request from "../../Api/ws/ws_request";
import { useContext, useEffect, useState } from "react";
import { WebSocketContext } from "../../App";
import EmptySpaceFieldValid from "../../CheckValid/EmptySpaceValid";
import CheckValid from "../../CheckValid/checkValid";
import { ClearFormSetFutios } from "../../redux/actions/adminAction";
import { useLocation } from "react-router-dom";

const CommonewModel = (props) => {
    const { showModalNew, setShowModalNew } = props;
    const { accessToken, OrderList, ClearFormSet,reportList,docTypeList } = useSelector((state) => state.adminReducers);
    const dispatch = useDispatch()
    function handleClose() {
        setShowModalNew({ ...showModalNew, open: false });
    }
    const orderDetail = OrderList?.orders?.filter((item) => item?.id === showModalNew?.Data?.orderId)[0]
    const productDetail = orderDetail?.items[0]
    const { websocket } = useContext(WebSocketContext);

    const [amount, setAmount] = useState("")
    const [notes, setNotes] = useState("")
    const [notesError, setErrorNotes] = useState("")
    const [amountError, setErrorAmount] = useState()
    const [topic, setTopic] = useState("")
    const LocationURL = useLocation()
    const logout = () => {
        CommonLogout(accessToken, dispatch)
    }
    useEffect(()=>{
        if(LocationURL?.pathname === '/page'){
            if(showModalNew?.title === "topic"){
                const reportListData = reportList?.list?.filter((item) => item?.id === showModalNew?.Data)[0]
                setTopic(reportListData?.title)
            }
            if(showModalNew?.title === "title"){
                const docType = docTypeList?.list?.filter((item) => item?.id === showModalNew?.Data)[0]
                setTopic(docType?.title)
            }
        }
    },[LocationURL])
    useEffect(() => {
       
        if ((ClearFormSet?.url === "faq_delete" || ClearFormSet?.url === "benefit_delete" || ClearFormSet?.url === "refund_edit" || ClearFormSet?.url === "report_edit"|| ClearFormSet?.url === "document_type_edit" || ClearFormSet?.url === "report_delete" || ClearFormSet?.url === "doc_type_delete")&&ClearFormSet?.action === 200) {
            handleClose()
        }
        setTimeout(() => {
            dispatch(ClearFormSetFutios({
                url: "",
                action: ""
            }))
        }, 1000)
    }, [ClearFormSet, dispatch,showModalNew])

    const submit = (e) => {
        e.preventDefault()
        if (showModalNew?.title === "topic") {
            let param = {
                 "transmit":"broadcast", 
                 "url": "report_edit",
                 "request": {
                    "report_id":showModalNew?.Data,
                    "title":topic
                }
            }
            wsSend_request(websocket, param)
        } if (showModalNew?.title === "title") {
            let param = {
                 "transmit": "broadcast", 
                 "url": "document_type_edit",
                 "request": {
                    "doc_type_id":showModalNew?.Data,
                    "title":topic
                }
            }
            wsSend_request(websocket, param)
        }
        if (showModalNew?.title === "report_delete") {
            let param = {
                "transmit": "broadcast",
                "url": "report_delete",
                "request": {
                    "report_id": showModalNew?.Data
                }
            }
            wsSend_request(websocket, param)
        } if (showModalNew?.title === "doc_type_delete") {
            let param = {
                "transmit": "broadcast",
                "url": "doc_type_delete",
                "request": {
                    "doc_type_id": showModalNew?.Data
                }
            }
            wsSend_request(websocket, param)
        }
        if (showModalNew?.modalType === "Reject") {
            if (notes) {
                let param = {
                    "transmit": "broadcast",
                    "url": "refund_edit",
                    "request": {
                        "transaction_id": showModalNew?.Data,
                        "status": showModalNew?.modalType,
                        "notes": notes
                    }
                }
                wsSend_request(websocket, param)
            } else {
                CheckValid(notes, { type: 'Notes', error: setErrorNotes })
            }
        }
        if (showModalNew?.modalType === "Accept" ||showModalNew?.modalType=== "Assign Credit") {
            if (amount && notes) {
                let param = {
                    "transmit": "broadcast",
                    "url": "refund_edit",
                    "request": {
                        "transaction_id": showModalNew?.modalType==="Assign Credit"? null : showModalNew?.Data,
                        "status": showModalNew?.modalType,
                        "notes": notes,
                        "amount": amount,
                        "user_id":showModalNew?.Data?.user_id,
                    }
                }
                wsSend_request(websocket, param)
            } else {
                CheckValid(amount, { type: 'Amount', error: setErrorAmount })
                CheckValid(notes, { type: 'Notes', error: setErrorNotes })
            }
        }
        if (showModalNew?.modalType === "delete-popup") {
            let param = {
                "transmit": "broadcast",
                "url": "faq_delete",
                "request": {
                    "faq_id": showModalNew?.Data
                }
            }
            wsSend_request(websocket, param)
        } else {
            let param = {
                "transmit": "broadcast",
                "url": "benefit_delete",
                "request": {
                    "benefit_id": showModalNew?.Data
                }
            }
            wsSend_request(websocket, param)
            //    handleClose()
        }
    }
    const getClassNames = (showModalNew) => {
        if (showModalNew?.subtitle === "Create New Model" && showModalNew?.modalType !== "Edit") {
            return "commonModel";
        } else if (showModalNew?.modalType === "Edit" && showModalNew?.subtitle === "Create New Model") {
            return "commonNewModel";
        } else {
            return "";
        }
    };
    console.log(showModalNew?.Data)
    return (
        <Modal show={showModalNew?.open} className={getClassNames(showModalNew)} >
            <>
                {showModalNew?.subtitle === "Create New Order Model" && <div class="card" style={{ width: "55rem" }}>
                    <div className="row ">
                        {/* <div className="col-6">
                            <span className="ms-4">Order Detail</span>
                        </div> */}
                        <div className="col-12">
                            <span className="model-close float-end me-3" onClick={handleClose}>X</span>
                        </div>
                    </div>
                    <Table>
                        <thead>
                            <tr>
                                <th style={{ border: "1px solid black" }}>Product Name</th>
                                <th style={{ border: "1px solid black" }}>Product Quantity</th>
                                <th style={{ border: "1px solid black" }}>Product Cost</th>
                            </tr>
                        </thead>
                        <tbody>
                            {productDetail ? (
                                <>
                                    <tr >
                                        <td style={{ border: "1px solid black" }}>{productDetail.product_name}</td>
                                        <td style={{ border: "1px solid black" }}>{productDetail.buy_count}</td>
                                        <td style={{ border: "1px solid black" }}>{`Rm ${productDetail.amount}`}</td>
                                    </tr>
                                    <tr>
                                        <th colSpan={2} className="text-end" style={{ border: "1px solid black" }}>SubTotal</th>
                                        <td style={{ border: "1px solid black" }}>{`Rm ${productDetail.sub_amount}`}</td>
                                    </tr>
                                </>
                            ) : (
                                <tr>
                                    <td colSpan={4} className="text-center">Order detail is empty</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>

                    {/* <div className="row p-2">
                        <div className="col-3"><img src={image} class="card-img-top" alt=""></img></div>
                        <div className="col-3"><span><strong>Order ID:</strong><br /> {order.orderId}</span></div>
                        <div className="col-3"><span><strong>Product Quantity:</strong> <br />{order.productQuantity}</span></div>
                        <div className="col-3"><span><strong>Product Cost:</strong> <br />{order.productCost}</span></div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-12"><span className="order-total"><strong>Sub Total:</strong> {order.productCost}</span></div>
                    </div> */}
                </div>}
                {showModalNew?.modalType === "log-out" && <div className="text-center">
                    <img src={logoutimg} height={61} width={61} alt=""></img>
                </div>}
                {/* {showModalNew?.modalType === "log-out" && <span className="log-out-text text-center ">Log Out</span>} */}
                {showModalNew?.modalType === "log-out" && <span className="log-out-footer">Are you sure you want to logout?</span>}
                {showModalNew?.modalType === "log-out" && <Modal.Footer>
                    <div>
                        <button className="ps-4 pe-4 pt-2 pb-2 mx-2" style={{ backgroundColor: "#181818", border: "unset", color: "#FFFFFF", borderRadius: "17px" }} onClick={logout} >Yes, Log Out</button>
                        <button className="ps-4 pe-4 pt-2 pb-2" style={{ backgroundColor: "#FFFFFF", border: "1.4px solid #000000", color: "#181818", borderRadius: "17px" }} onClick={handleClose}> Cancel</button>
                    </div>
                </Modal.Footer>}
                {(showModalNew?.modalType === "delete-popup" || showModalNew?.modalType === "delete-popup-benefit") && <span className="log-out-footer">Are you sure you want to delete?</span>}
                {(showModalNew?.modalType === "delete-popup" || showModalNew?.modalType === "delete-popup-benefit") && <Modal.Footer>
                    <div>
                        <button className="ps-4 pe-4 pt-2 pb-2 mx-2" style={{ backgroundColor: "#181818", border: "unset", color: "#FFFFFF", borderRadius: "17px" }} onClick={submit} >Yes, Delete</button>
                        <button className="ps-4 pe-4 pt-2 pb-2" style={{ backgroundColor: "#FFFFFF", border: "1.4px solid #000000", color: "#181818", borderRadius: "17px" }} onClick={handleClose}> Cancel</button>
                    </div>
                </Modal.Footer>}
                {/* {showModalNew?.modalType === "log-out" && <span className="log-out-footer">Are you sure you want to logout?</span>}
                {showModalNew?.modalType === "log-out" && <Modal.Footer>
                    <div>
                        <button className="ps-4 pe-4 pt-2 pb-2 mx-2" style={{ backgroundColor: "#181818", border: "unset", color: "#FFFFFF", borderRadius: "17px" }} onClick={logout} >Yes, Log Out</button>
                        <button className="ps-4 pe-4 pt-2 pb-2" style={{ backgroundColor: "#FFFFFF", border: "1.4px solid #000000", color: "#181818", borderRadius: "17px" }} onClick={handleClose}> Cancel</button>
                    </div>
                </Modal.Footer>} */}
                {(showModalNew?.modalType === "Reject" || showModalNew?.modalType === "Accept") && <span className="status_refund mb-2">{showModalNew?.modalType}</span>}
                {showModalNew?.modalType === "Accept" &&
                    <input type="number"
                        placeholder="Enter amount here.."
                        className="form-control mb-2"
                        onChange={(e) => setAmount(e.target.value)}
                        onKeyDown={EmptySpaceFieldValid}
                        onKeyUp={(e) => CheckValid(e.target.value, { type: 'Amount', error: setErrorAmount })}
                        min={0} />}
                {amountError && <span className="text-danger">{amountError}</span>}
                {(showModalNew?.modalType === "Reject" || showModalNew?.modalType === "Accept") &&
                    <input type="text"
                        placeholder="Enter notes here.."
                        className="form-control mb-2"
                        onChange={(e) => setNotes(e.target.value)}
                        onKeyDown={EmptySpaceFieldValid}
                        onKeyUp={(e) => CheckValid(e.target.value, { type: 'Notes', error: setErrorNotes })}
                    />}
                {notesError && <span className="text-danger">{notesError}</span>}
                {(showModalNew?.modalType === "Reject" || showModalNew?.modalType === "Accept") && <Modal.Footer >
                    <div>
                        <button className="ps-4 pe-4 pt-2 pb-2" style={{ backgroundColor: "#181818", border: "unset", color: "#FFFFFF", borderRadius: "17px" }} onClick={submit} >submit</button>
                        <button className="ps-4 pe-4 pt-2 pb-2 ms-2" style={{ backgroundColor: "#FFFFFF", border: "1.4px solid #000000", color: "#181818", borderRadius: "17px" }} onClick={handleClose}>Cancel</button>
                    </div>

                </Modal.Footer>}
                {showModalNew?.modalType === "Assign Credit"  && 
                <>
                <span style={{fontWeight:"700",color:"#000000"}}>Assign Credit</span>
                <span>Balance: {showModalNew?.Data?.Amt}</span>
                </>
                }

                {showModalNew?.modalType === "Assign Credit"  && <span className="status_refund mb-2">Credit can be added or deducted </span>}
                {showModalNew?.modalType === "Assign Credit" &&
                    <input type="number"
                        placeholder="Enter amount here.."
                        className="form-control mb-2"
                        onChange={(e) => setAmount(e.target.value)}
                        onKeyDown={EmptySpaceFieldValid}
                        min={0} />}
                       {showModalNew?.modalType === "Assign Credit" && <p>Enter amount eg. +10 or -10</p>}
                {showModalNew?.modalType === "Assign Credit" &&<p>Reason</p>}   
                {showModalNew?.modalType === "Assign Credit" &&
                    <input type="text"
                        placeholder="Enter reason here.."
                        className="form-control mb-2"
                        onChange={(e) => setNotes(e.target.value)}
                        onKeyDown={EmptySpaceFieldValid}
                    />}
                {showModalNew?.modalType === "Assign Credit" && <Modal.Footer >
                    <div>
                        <button className="ps-4 pe-4 pt-2 pb-2 ms-2 mx-2" style={{ backgroundColor: "#FFFFFF", border: "1.4px solid #000000", color: "#181818", borderRadius: "17px" }} onClick={handleClose}>Cancel</button>
                        <button className="ps-4 pe-4 pt-2 pb-2 ms-2" style={{ backgroundColor: "#181818", border: "unset", color: "#FFFFFF", borderRadius: "17px" }} onClick={submit} >save</button>
                    </div>

                </Modal.Footer>}

                {showModalNew?.modalType === "Edit" && <span className="status_refund mb-2"> {`${showModalNew?.modalType}  ${showModalNew?.title==="topic"?"Topic":"Document Type"}`}</span>}
                {showModalNew?.modalType === "Edit" && (
                    <>
                        <input
                            type="text"
                            value={topic}
                            placeholder={showModalNew?.title ==="topic"? "Enter Topic here..":"Enter title Here"}
                            className="form-control mb-2"
                            onChange={(e) => setTopic(e.target.value)}
                            onKeyDown={EmptySpaceFieldValid}
                        />
                    </>
                )}
                  {showModalNew?.modalType === "Edit" && <Modal.Footer >
                    <div>
                        <button className="ps-4 pe-4 pt-2 pb-2" style={{ backgroundColor: "#181818", border: "unset", color: "#FFFFFF", borderRadius: "17px" }} onClick={submit} >Edit</button>
                        <button className="ps-4 pe-4 pt-2 pb-2 ms-2" style={{ backgroundColor: "#FFFFFF", border: "1.4px solid #000000", color: "#181818", borderRadius: "17px" }} onClick={handleClose}>Cancel</button>
                    </div>

                </Modal.Footer>}
            </>
        </Modal>
    )
}

export default CommonewModel;